import { useEffect, useState } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { type InjectedFormProps, reduxForm } from "redux-form";

import { Feature } from "graphql_globals";
import {
  useMfaOptions,
  getSupportedAuthenticationRequirements,
} from "common/authentication/mfa_options";
import { useQuery, useMutation } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { composeValidators, getFormValues } from "util/form";
import compose from "util/compose";
import LoadingIndicator from "common/core/loading_indicator";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import SaveButton from "common/core/save_button";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { pushNotification } from "common/core/notification_center/actions";
import { Card, CardHeading, CardSection, CardText } from "common/core/card";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { validateIf, validatePresenceOfOne } from "validators/form";
import MfaIcon from "assets/images/mfa_icon.svg";
import { useId } from "util/html";
import { Heading, Paragraph } from "common/core/typography";
import { BinaryToggle } from "common/core/form/binary_toggle";

import AccountAuthOptions from "./mfa";
import AccountAuthOptionsQuery, {
  type Organization_organization_Organization as OrganizationType,
} from "./mfa/account_auth_options_query.graphql";
import UpdateAccountAuthOptionsMutation from "./mfa/update_account_auth_options_mutation.graphql";
import Styles from "../profile/overview/user_security_form/user_mfa.module.scss";
import CommonStyles from "../profile/overview/index.module.scss";
import { SamlProvider, type FormValues as SamlProviderProps } from "./saml";
import CreateSamlProviderMutation from "./saml/create_saml_provider_mutation.graphql";
import UpdateSamlProviderMutation from "./saml/update_saml_provider_mutation.graphql";

type SecurityFeatures = "none" | "saml" | "openId" | "multiFactor";

type LoginSecurityState =
  | { status: null }
  | { status: "loading" }
  | { status: "error"; message: null | string };

type FormValues = {
  securityFeature: SecurityFeatures;
} & SamlProviderProps;

type GetFormValueProps = {
  formValues: FormValues;
};

type Props = {
  organization: OrganizationType;
  showSaml: boolean;
};

type ReduxFormProps = GetFormValueProps & InjectedFormProps<FormValues, Props>;
type InnerProps = Props & ReduxFormProps;

const MESSAGES = defineMessages({
  none: {
    id: "ca808619-c285-46ff-8299-a1f12e89a493",
    defaultMessage: "No extra security (password only)",
  },
  saml: {
    id: "f5a120b1-bc0d-4221-b35d-a25bfda96dd9",
    defaultMessage: "Single Sign-On (SAML)",
  },
  multiFactor: {
    id: "be164360-6b63-4af1-bbb6-d4d13267d2f2",
    defaultMessage: "Multi-factor authentication",
  },
  success: {
    id: "fc9c9c04-7204-4ea6-a50c-dd3d566643ef",
    defaultMessage: "You have successfully updated your security settings",
  },
  metadataUrlUnreadableError: {
    id: "2821ab4b-fae2-462f-8691-f6c80159c950",
    defaultMessage: "This metadata URL cannot be read",
  },
  invalidUrlError: {
    id: "8f47a3d6-4698-4e17-897c-730463f82c32",
    defaultMessage: "Invalid URL",
  },
  invalidCertificateError: {
    id: "b334b6e4-6ac1-44c4-8692-8daa4fb44082",
    defaultMessage: "Invalid certificate",
  },
  missingRequiredInfoError: {
    id: "856f0407-9714-4f6e-84d5-e096a79f0191",
    defaultMessage:
      "One of the following is required: Metadata URL, Metadata File, or Target URL + Entity ID + X.509 Certificate",
  },
  missingOrInvalidDataError: {
    id: "270f4b04-e1e8-4933-b11d-7c0cfb10e757",
    defaultMessage:
      "Target URL or Entity ID appear to be missing or invalid. If you passed a Metadata URL of File, make sure these values are present and correct with the Metadata.",
  },
  mutationError: {
    id: "653c454f-6797-43d2-8805-5f3311d71779",
    defaultMessage:
      "We were unable to save your changes at this time. Please try again or reach out to customer support.",
  },
});

function validate(values: FormValues) {
  return composeValidators(
    validateIf({
      field: "metadataUrl",
      condition: () => !(values.ssoTargetUrl && values.entityId && values.s3Key),
      validation: validatePresenceOfOne({
        primaryField: "metadataUrl",
        primaryLabel: "metadata URL",
        secondaryField: "metadataS3Key",
        secondaryLabel: "XML file",
      }),
    }),
  )(values);
}

function LoginSecurity({
  organization: { id, authenticationRequirements, featureList, samlProvider },
  showSaml,
  handleSubmit,
  initialize,
  change,
  formValues,
  invalid,
}: InnerProps) {
  const intl = useIntl();
  const [state, setState] = useState<LoginSecurityState>({ status: null });
  const toggleId = useId();

  const updateAccountAuthOptions = useMutation(UpdateAccountAuthOptionsMutation);

  const createSamlProvider = useMutation(CreateSamlProviderMutation);

  const updateSamlProvider = useMutation(UpdateSamlProviderMutation);

  const { authTypes, setAuthTypes } = useMfaOptions({
    authenticationRequirements,
  });

  const securityFeature = samlProvider?.active
    ? "saml"
    : authenticationRequirements.length > 0
      ? "multiFactor"
      : "none";
  const hasSamlEnabled = featureList.includes(Feature.SINGLE_SIGN_ON);

  useEffect(() => {
    initialize({
      securityFeature,
      ...samlProvider,
    });
  }, []);

  const onAuthOptionsSubmit = (securityFeature: SecurityFeatures) => {
    const authenticationRequirements =
      securityFeature === "none" ? [] : getSupportedAuthenticationRequirements({ authTypes });

    setState({ status: "loading" });

    return updateAccountAuthOptions({
      variables: {
        input: {
          id,
          authenticationRequirements,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.success),
        });
        setState({ status: null });
      })
      .catch(() => {
        setState({ status: "error", message: intl.formatMessage(MESSAGES.mutationError) });
      });
  };

  const onSamlSubmit = (values: FormValues, activeState: { active: boolean }) => {
    const samlValues = {
      entityId: values.entityId,
      metadataS3Key: values.metadataS3Key,
      metadataUrl: values.metadataUrl,
      s3Key: values.s3Key,
      ssoTargetUrl: values.ssoTargetUrl,
      active: activeState.active,
    };

    setState({ status: "loading" });

    const operation = samlProvider?.id
      ? updateSamlProvider({
          variables: {
            input: {
              id: samlProvider.id,
              ...samlValues,
            },
          },
        })
      : createSamlProvider({
          variables: {
            input: {
              organizationId: id,
              ...samlValues,
            },
          },
        });

    return (operation as Promise<Awaited<typeof operation>>)
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.success),
        });
        setState({ status: null });
      })
      .catch((error: Error) => {
        if (error.message.includes("invalid_url")) {
          setState({ status: "error", message: intl.formatMessage(MESSAGES.invalidUrlError) });
        } else if (error.message.includes("metadata_url_unreadable")) {
          setState({
            status: "error",
            message: intl.formatMessage(MESSAGES.metadataUrlUnreadableError),
          });
        } else if (error.message.includes("invalid_certificate")) {
          setState({
            status: "error",
            message: intl.formatMessage(MESSAGES.invalidCertificateError),
          });
        } else if (error.message.includes("missing_required_info")) {
          setState({
            status: "error",
            message: intl.formatMessage(MESSAGES.missingRequiredInfoError),
          });
        } else if (error.message.includes("missing_or_invalid_data")) {
          setState({
            status: "error",
            message: intl.formatMessage(MESSAGES.missingOrInvalidDataError),
          });
        } else {
          setState({ status: "error", message: intl.formatMessage(MESSAGES.mutationError) });
        }
      });
  };

  const onSubmit = async (values: FormValues) => {
    switch (values.securityFeature) {
      case "none":
        hasSamlEnabled && (await onSamlSubmit(values, { active: false }));
        await onAuthOptionsSubmit("none");
        break;
      case "multiFactor":
        onAuthOptionsSubmit("multiFactor");
        break;
      case "saml":
        onSamlSubmit(values, { active: true });
        break;
    }
  };

  const formFor = (selected: SecurityFeatures) => {
    switch (selected) {
      case "saml":
        return (
          <SamlProvider
            active={Boolean(samlProvider?.active)}
            reactivateSettings={() => onSamlSubmit(formValues, { active: true })}
            change={change}
          />
        );
      case "multiFactor":
        return <AccountAuthOptions authTypes={authTypes} setAuthTypes={setAuthTypes} />;
    }
  };

  const disableSaveButton =
    invalid ||
    (formValues.securityFeature === "multiFactor" &&
      !Object.values(authTypes).some((authTypes) => authTypes));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        footer={
          <SaveButton
            disabled={disableSaveButton}
            submitting={state.status === "loading"}
            title={
              <FormattedMessage
                id="7a84efc1-4b69-4647-a415-f4188e23dbd2"
                defaultMessage="Save Changes"
              />
            }
          />
        }
      >
        <CardSection>
          <CardHeading>
            {!showSaml && (
              <>
                <img src={MfaIcon} aria-hidden="true" alt="" width="80" />
                <div className={Styles.wrapper}>
                  <Heading level="h3" textStyle="headingSix" className={CommonStyles.heading}>
                    <FormattedMessage
                      id="c91e46ac-ef3b-4af8-b35a-86759946552b"
                      defaultMessage="Multi-factor authentication"
                    />
                  </Heading>
                  <Paragraph className={CommonStyles.copy}>
                    <FormattedMessage
                      id="5fe8bb3f-4ca9-43ca-a054-9ddfa7a30f49"
                      defaultMessage="Add extra security to your team’s login experience with multi-factor authentication."
                    />
                  </Paragraph>
                </div>
              </>
            )}
            {showSaml && (
              <FormattedMessage
                id="cf2e5f5f-a1e6-4189-ac33-bae45c7d08c8"
                defaultMessage="Add extra security to your team’s login experience with single sign-on or multi-factor authentication."
              />
            )}
          </CardHeading>
          {!showSaml && (
            <div className={Styles.mfaToggleWrapper}>
              <BinaryToggle
                aria-labelledby={toggleId}
                className={Styles.mfaToggle}
                onChange={(newValue) => {
                  change("securityFeature", newValue ? "multiFactor" : "none");
                }}
                value={formValues.securityFeature === "multiFactor"}
              />
              <span id={toggleId} className={Styles.onOffText}>
                <FormattedMessage
                  id="7bc77589-cedc-4dc0-9e02-35c438d1d4b5"
                  defaultMessage="{mfaToggleValue, select, true{On} other{Off}}"
                  values={{ mfaToggleValue: formValues.securityFeature === "multiFactor" }}
                />
              </span>
            </div>
          )}
          {showSaml && (
            <>
              <CardText>
                <DeprecatedRadioButtonField
                  name="securityFeature"
                  automationId="login-security-none"
                  radioValue="none"
                  size="regular"
                  labelText={intl.formatMessage(MESSAGES.none)}
                />
              </CardText>
              <CardText>
                <DeprecatedRadioButtonField
                  name="securityFeature"
                  automationId="login-security-mfa"
                  radioValue="multiFactor"
                  size="regular"
                  labelText={intl.formatMessage(MESSAGES.multiFactor)}
                />
              </CardText>
              {hasSamlEnabled && (
                <CardText>
                  <DeprecatedRadioButtonField
                    name="securityFeature"
                    automationId="login-security-saml"
                    radioValue="saml"
                    size="regular"
                    labelText={intl.formatMessage(MESSAGES.saml)}
                  />
                </CardText>
              )}
            </>
          )}
        </CardSection>

        {formFor(formValues.securityFeature)}
      </Card>

      {state.status === "error" && (
        <MutationErrorModal message={state.message} onClick={() => setState({ status: null })} />
      )}
    </form>
  );
}

const WithForm = compose(
  reduxForm<FormValues, Props>({ form: "loginSecurity", validate }),
  getFormValues<InnerProps>("loginSecurity"),
)(LoginSecurity);

export default function WithAuthRequirements() {
  const [activeOrganizationId] = useActiveOrganization();
  const { loading, data } = useQuery(AccountAuthOptionsQuery, {
    variables: { organizationId: activeOrganizationId! },
  });
  const organization = data?.organization;
  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  if (loading || !organization) {
    return <LoadingIndicator />;
  }

  const showSaml = organization.legacyOrgBasedSsoEnabled;

  return <WithForm organization={organization} showSaml={showSaml} />;
}
