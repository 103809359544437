import { useEffect, useCallback, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { reduxForm, type InjectedFormProps, SubmissionError } from "redux-form";
import { isValid, isFuture } from "date-fns";

import { DIGITAL_NOTARY_CERT_HELP_URL, HOW_TO_DOWNLOAD_DIGITAL_CERT_URL } from "constants/support";
import { customMessage } from "errors/form";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedSubForm } from "common/form/sub_form";
import Link from "common/core/link";
import {
  ValidationRequirements,
  NotaryProfileInvalidFields as InvalidFields,
  CertificateMigrationStatus,
} from "graphql_globals";
import { validatePresence } from "validators/form";
import type { FormError } from "errors/util";
import { b } from "util/html";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import { shouldMigrateToProofCertificate } from "common/notary/profile_wizard/section/digital_certificate/proof";

import NotaryCertUploader from "./cert_uploader";
import type {
  NotaryProfileWizardDigitalCertificate as User,
  NotaryProfileWizardDigitalCertificate_notaryProfile as NotaryProfile,
} from "./index_fragment.graphql";
import type { SubmitType } from "../section_utils";
import UpdateNotaryIdentrustCertificateMutation from "./identrust/update_notary_identrust_certificate.graphql";

export const DIGITAL_CERTIFICATE_PATH = "digital-certificate";

// This form is weird. We only validate a "side effect" of the certificate, not the cert
// key or passphrase themselves.
type FormValues = {
  certExpiry: string;
  email: string | null;
};
type CertError = {
  cert: string;
};
type Props = {
  user: User;
  onNext: (input: null, skipConfig?: "DontNavigate") => Promise<User>;
  renderFooter: (handleSubmit: () => SubmitType, disabled?: boolean) => ReactNode;
};
type InnerProps = InjectedFormProps<FormValues, Props> & Props;

type DigitalCertificateType =
  | { id: "DigitalCertificate"; completed: boolean; route: typeof DIGITAL_CERTIFICATE_PATH }
  | false;

export function certificateSection(
  lookup: Set<ValidationRequirements>,
  notaryProfile: NotaryProfile,
  migrateExpiringNotaries: boolean,
): DigitalCertificateType {
  const fields = [InvalidFields.MISSING_CERT_KEY, InvalidFields.INVALID_CERT_EXPIRY];
  const completed = !fields.some((field) => notaryProfile.validation.invalidFields.includes(field));
  const migrateToProofCertificate = shouldMigrateToProofCertificate(
    migrateExpiringNotaries,
    notaryProfile.certExpiry,
    notaryProfile.certificateMigrationStatus,
  );

  if (
    notaryProfile.certificateMigrationStatus === CertificateMigrationStatus.PROOF_CERTIFICATE ||
    migrateToProofCertificate
  ) {
    return false;
  }

  return (
    lookup.has(ValidationRequirements.CERTIFICATE) && {
      id: "DigitalCertificate",
      completed,
      route: DIGITAL_CERTIFICATE_PATH,
    }
  );
}

function DigitalCertificate({
  user,
  initialize,
  change,
  renderFooter,
  handleSubmit,
  onNext,
}: InnerProps) {
  const notaryProfile = user.notaryProfile!;
  const { certExpiry } = notaryProfile;
  useEffect(() => {
    initialize({ certExpiry: certExpiry || "", email: user.email });
  }, []);
  const updateNotaryIdentrustCertifiicateMutateFn = useMutation(
    UpdateNotaryIdentrustCertificateMutation,
  );
  const handleUploadSubmit = useCallback(
    async (key: string, passphrase: string) => {
      try {
        const response = await updateNotaryIdentrustCertifiicateMutateFn({
          variables: {
            input: {
              certKey: key,
              certPassword: passphrase,
              id: user.notaryProfile!.id,
            },
          },
        });
        await onNext(null, "DontNavigate");
        change(
          "certExpiry",
          response.data!.updateNotaryIdentrustCertificate!.notaryProfile.certExpiry!,
        );
      } catch (error) {
        if (isGraphQLError(error) && error.message.includes("invalid_certificate")) {
          throw new SubmissionError<CertError, FormError>({
            cert: customMessage({
              message: "Digital Certificate and/or password is invalid.",
            }),
          });
        } else {
          captureException(error);
          throw new SubmissionError<CertError, FormError>({
            cert: customMessage({
              message: "Unable to process Certificate.",
            }),
          });
        }
      }
    },
    [onNext, change, certExpiry],
  );

  // Nothing to serialize at this point, we skip the mutation and just move on.
  const serializeForm = (values: FormValues) => {
    const date = new Date(values.certExpiry);
    if (!isValid(date) || !isFuture(date)) {
      throw new SubmissionError<FormValues, FormError>({
        certExpiry: customMessage({
          message: "Digital Certificate Expiration Date must be a valid future date",
        }),
      });
    }
    return onNext(null);
  };

  return (
    <>
      <div>
        <FormattedMessage
          id="a2a57b97-2618-42d1-b67a-a4f125510dd7"
          defaultMessage="Digital Certificate"
          tagName="h3"
        />
        <FormattedMessage
          id="256ad148-b6d7-470d-bfff-f1515f56091e"
          defaultMessage="Please ensure the name on your digital certificate matches your name exactly as it appears on your commission."
          tagName="strong"
        />
        <FormattedMessage
          id="7d6730f4-a8b6-4a57-9a4d-108fa6bb8357"
          tagName="p"
          defaultMessage="Follow these instructions on <slink>how to get a digital certificate</slink>. For now, the Notarize Network is only compatible with IdenTrust digital certificates. Continue with your profile once you have a digital certificate."
          values={{
            slink: (msg) => <Link href={DIGITAL_NOTARY_CERT_HELP_URL}>{msg}</Link>,
          }}
        />
        <FormattedMessage
          id="20d8ac9a-e45e-452a-b8d7-5b566b46a9d2"
          tagName="p"
          defaultMessage="If you already have a digital certificate, here are instructions on <slink>how to download it</slink> from IdenTrust:"
          values={{
            slink: (msg) => <Link href={HOW_TO_DOWNLOAD_DIGITAL_CERT_URL}>{msg}</Link>,
          }}
        />
        <ul className="instructions">
          <FormattedMessage
            id="11a7ca90-a52c-4876-877e-bf34bfd918ba"
            tagName="li"
            defaultMessage="On a PC, upload the .pem or .pfx certificate from IdenTrust."
          />
          <FormattedMessage
            id="303ae021-caf9-4791-af00-8137d82b32fd"
            tagName="li"
            defaultMessage="On a MacBook, go to your key chain and scroll down to certificates. Find the certificate and right click to export to a p.12 file."
          />
        </ul>
        <DeprecatedSubForm>
          <FormattedMessage
            id="c512d921-585b-4074-83ef-b358a850ce1f"
            defaultMessage="<b>Upload IdenTrust signing certificate</b> (.pem, .pfx, or p.12)"
            values={{ b }}
            tagName="p"
          />
          <NotaryCertUploader
            onSubmit={handleUploadSubmit}
            notaryProfile={notaryProfile}
            onCancel={handleSubmit(() => {
              throw new SubmissionError<FormValues, FormError>({
                certExpiry: customMessage({
                  message:
                    "You must provide the password for your digital certificate. Please try uploading again.",
                }),
              });
            })}
          />
          <FormGroupErrors fields={["certExpiry"]} />
        </DeprecatedSubForm>
      </div>
      {renderFooter(handleSubmit(serializeForm))}
    </>
  );
}

export default reduxForm<FormValues, Props>({
  form: "notaryProfileWizardDigitalCertificate",
  validate: validatePresence({ field: "certExpiry", label: "Digital Certificate" }),
})(DigitalCertificate);
