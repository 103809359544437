import { useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import {
  ValidationRequirements,
  NotaryProfileInvalidFields as InvalidFields,
  CertificateMigrationStatus,
  NotaryOnboardingStatuses,
} from "graphql_globals";
import { Paragraph } from "common/core/typography";
import SealCreatorModal from "common/notary/profile_wizard/seal/creator_modal";
import Icon from "common/core/icon";
import Button from "common/core/button";
import Idea from "assets/images/idea.svg";
import { simpleAssetUpload } from "util/uploader";
import { downloadAs } from "util/http";
import SmudgedSignature from "assets/images/bad_notary_signatures/smudged.svg";
import LineBrokenSignature from "assets/images/bad_notary_signatures/line_broken.svg";
import SidewaysSignature from "assets/images/bad_notary_signatures/sideways.svg";

import { SignatureInitialsModal } from "./asset";
import type {
  NotaryProfileWizardSignatureAndSeal as User,
  NotaryProfileWizardSignatureAndSeal_notaryProfile as NotaryProfile,
} from "./index_fragment.graphql";
import type { SubmitType } from "../section_utils";
import Styles from "./index.module.scss";
import ExportExemplarButton from "../../../exemplar/export_button";
import { notaryHasValidProofCertificate } from "../digital_certificate/proof";

export const SIGNATURE_SEAL_PATH = "signature-and-seal";

type Input = { signature: string } | { initials: string } | { seal: string } | null;
type Props = {
  user: User;
  onNext: (input: Input, config?: "DontNavigate") => Promise<User>;
  renderFooter: (handleSubmit: () => SubmitType) => ReactNode;
};
type SignatureAndSealType =
  | { id: "SignatureAndSeal"; completed: boolean; route: typeof SIGNATURE_SEAL_PATH }
  | false;
type UploadSectionProps = {
  header: ReactNode;
  description: ReactNode;
  children: ReactNode;
};

export function sigAndSealSection(
  lookup: Set<ValidationRequirements>,
  notaryProfile: NotaryProfile,
): SignatureAndSealType {
  const fields = [
    InvalidFields.MISSING_SIGNATURE,
    InvalidFields.MISSING_INITIALS,
    InvalidFields.MISSING_SEAL,
  ];
  const completed = !fields.some((field) => notaryProfile.validation.invalidFields.includes(field));
  return (
    lookup.has(ValidationRequirements.SIGNING_ASSETS) && {
      id: "SignatureAndSeal",
      completed,
      route: "signature-and-seal",
    }
  );
}

function TipItem(props: { children: ReactNode; figureSrc: string }) {
  return (
    <li>
      <Icon name="x-filled" className={Styles.tipFailureIcon} />
      <figure>
        <img src={props.figureSrc} alt="" />
        <figcaption className={Styles.tipText}>{props.children}</figcaption>
      </figure>
    </li>
  );
}

function UploadSection(props: UploadSectionProps) {
  return (
    <div className={Styles.uploadSection}>
      <h4>{props.header}</h4>
      <p className={Styles.sectionDesc}>{props.description}</p>
      {props.children}
    </div>
  );
}

function PreviewAsset(props: { src: string }) {
  return (
    <img
      className={Styles.previewAsset}
      src={props.src}
      alt=""
      crossOrigin="anonymous"
      aria-hidden="true"
    />
  );
}

function UpdateButton(props: { onClick: () => void }) {
  return (
    <Button buttonColor="action" variant="primary" onClick={props.onClick}>
      <FormattedMessage id="3ba571d7-9133-47ec-9353-aad153f366d6" defaultMessage="Update" />
    </Button>
  );
}

function DownloadButton(props: { filename: string; href: string }) {
  return (
    <Button
      buttonColor="action"
      variant="secondary"
      className={Styles.downloadLink}
      // incase there are some random svg's laying around, let's not corrupt filenames
      onClick={() =>
        downloadAs(
          props.href,
          props.href.includes(".png") ? `${props.filename}.png` : `${props.filename}.svg`,
        )
      }
    >
      <Icon className="icon" name="download" />
      <FormattedMessage id="541fa8fe-73db-4932-b172-4868beb7b321" defaultMessage="Download" />
    </Button>
  );
}

function MissingError(props: { children: ReactNode }) {
  return <div className={Styles.missingError}>{props.children}</div>;
}

function SignatureAndSeal(props: Props) {
  const notaryProfile = props.user.notaryProfile!;
  const [openModal, setOpenModal] = useState<null | "signature" | "seal" | "initials">(null);
  const [submitted, setSubmitted] = useState(false);
  const showExemplarExportButton =
    notaryProfile.certificateMigrationStatus === CertificateMigrationStatus.PROOF_CERTIFICATE &&
    notaryHasValidProofCertificate(notaryProfile) &&
    notaryProfile.onboardingStatus === NotaryOnboardingStatuses.COMPLETED;
  const handleAssetCreate = async (assetKey: NonNullable<typeof openModal>, asset: Blob) => {
    const s3Key = await simpleAssetUpload({ asset });
    return props.onNext({ [assetKey]: s3Key } as Record<typeof assetKey, string>, "DontNavigate");
  };
  const handleSigInitialsCreate = async (assetKey: "signature" | "initials", data: string) => {
    const res = await fetch(data);
    await handleAssetCreate(assetKey, await res.blob());
    setOpenModal(null);
  };
  return (
    <>
      <div className={Styles.header}>
        <FormattedMessage
          id="f9650a35-9b25-4f38-a4e7-6b6da38683f0"
          defaultMessage="Create your Signature and Seal"
          tagName="h3"
        />
        <Paragraph>
          <FormattedMessage
            id="ea96ad55-c0ba-4b3d-b01c-f4bce3e36c2f"
            defaultMessage="These will be used to notarize documents on our platform. Please ensure the signature and initials exactly match the name on your Commission."
          />
        </Paragraph>
        <div className={Styles.tips}>
          <h4>
            <img src={Idea} alt="" aria-hidden="true" />
            <FormattedMessage
              id="50abcdb3-355e-4cd0-845d-4550c80adc12"
              defaultMessage="How to upload a clear signature and initials"
            />
          </h4>
          <Paragraph>
            <FormattedMessage
              id="baf05605-26a1-48fd-81be-5406795d2a1a"
              defaultMessage="Smudges, line breaks, or sideways signatures will not be accepted and may delay your onboarding"
            />
          </Paragraph>
          <ul>
            <TipItem figureSrc={SmudgedSignature}>
              <FormattedMessage
                id="ac2c124e-9793-43ce-90f2-cca43023df52"
                defaultMessage="Avoid blue smudges"
              />
            </TipItem>
            <TipItem figureSrc={LineBrokenSignature}>
              <FormattedMessage
                id="29f54013-5eee-4adf-bec3-adee1a31a147"
                defaultMessage="Avoid line breaks"
              />
            </TipItem>
            <TipItem figureSrc={SidewaysSignature}>
              <FormattedMessage
                id="f78d0514-c20f-4bb7-ba88-7598bd30c9b4"
                defaultMessage="Avoid sideways signature"
              />
            </TipItem>
          </ul>
        </div>
      </div>
      <section>
        <UploadSection
          header={
            <FormattedMessage
              id="52745119-cf2f-4250-83bc-5655bbedce10"
              defaultMessage="Your signature"
            />
          }
          description={
            <FormattedMessage
              id="8309b4ce-6c2e-41d9-bae4-f9ff9520e3a4"
              defaultMessage="Create a handrawn or scanned signature"
            />
          }
        >
          {notaryProfile.signatureInfo ? (
            <>
              <PreviewAsset src={notaryProfile.signatureInfo.assetUrl!.url!} />
              <UpdateButton onClick={() => setOpenModal("signature")} />
              <DownloadButton
                filename="signature"
                href={notaryProfile.signatureInfo.assetUrl!.url!}
              />
            </>
          ) : (
            <>
              <Button
                buttonColor="action"
                variant="primary"
                onClick={() => setOpenModal("signature")}
              >
                <Icon name="pen-tip" />
                <FormattedMessage
                  id="73d4d94b-cffe-4c45-a294-5a8d753dc807"
                  defaultMessage="Create signature"
                />
              </Button>
              {submitted && (
                <MissingError>
                  <FormattedMessage
                    id="6c643e41-57f4-4406-b1bb-f6ee7c17b7aa"
                    defaultMessage="Missing signature"
                  />
                </MissingError>
              )}
            </>
          )}
        </UploadSection>
        <UploadSection
          header={
            <FormattedMessage
              id="3a06c4bf-aa8c-4117-a715-163862c88514"
              defaultMessage="Your initials"
            />
          }
          description={
            <FormattedMessage
              id="8762677b-c3c0-45f2-9f68-9f70c565986a"
              defaultMessage="Create handrawn or scanned initials"
            />
          }
        >
          {notaryProfile.initialsInfo ? (
            <>
              <PreviewAsset src={notaryProfile.initialsInfo.assetUrl!.url!} />
              <UpdateButton onClick={() => setOpenModal("initials")} />
              <DownloadButton
                filename="initials"
                href={notaryProfile.initialsInfo.assetUrl!.url!}
              />
            </>
          ) : (
            <>
              <Button
                buttonColor="action"
                variant="primary"
                onClick={() => setOpenModal("initials")}
              >
                <Icon name="pen-tip" />
                <FormattedMessage
                  id="df6723fe-df6d-4d78-8ef9-86a76d591d77"
                  defaultMessage="Create initials"
                />
              </Button>
              {submitted && (
                <MissingError>
                  <FormattedMessage
                    id="c0427451-bac9-49be-8482-13f9c6923870"
                    defaultMessage="Missing initials"
                  />
                </MissingError>
              )}
            </>
          )}
        </UploadSection>
        <UploadSection
          header={
            <FormattedMessage
              id="dc97f5a4-15e1-49f5-a70d-4ed8bbfc84be"
              defaultMessage="Your seal"
            />
          }
          description={
            <FormattedMessage
              id="afcbe56c-d480-4b16-8d33-e716e1fb7cef"
              defaultMessage="Create a seal"
            />
          }
        >
          {notaryProfile.sealInfo ? (
            <>
              <PreviewAsset src={notaryProfile.sealInfo.assetUrl!.url!} />
              <UpdateButton onClick={() => setOpenModal("seal")} />
              <DownloadButton filename="seal" href={notaryProfile.sealInfo.assetUrl!.url!} />
              {showExemplarExportButton && <ExportExemplarButton className={Styles.downloadLink} />}
            </>
          ) : (
            <>
              <Button buttonColor="action" variant="primary" onClick={() => setOpenModal("seal")}>
                <Icon name="notary-seal" />
                <FormattedMessage
                  id="a21d8eff-108a-480b-846c-bbadb21e4315"
                  defaultMessage="Create seal"
                />
              </Button>
              {submitted && (
                <MissingError>
                  <FormattedMessage
                    id="a3b47ee8-56cc-4009-85b3-751ca3c19323"
                    defaultMessage="Missing seal"
                  />
                </MissingError>
              )}
            </>
          )}
          {showExemplarExportButton && (
            <Paragraph size="small" textColor="subtle">
              <FormattedMessage
                id="47d2f974-2183-4e75-a6f6-e2376612616b"
                defaultMessage="Not all states require exemplar submission for compliance. No action needed if not applicable to your state."
              />
            </Paragraph>
          )}
        </UploadSection>
      </section>

      {props.renderFooter(() => {
        const complete =
          notaryProfile.signatureInfo && notaryProfile.initialsInfo && notaryProfile.sealInfo;
        setSubmitted(true);
        return props.onNext(null, complete ? undefined : "DontNavigate");
      })}

      {openModal === "seal" ? (
        <SealCreatorModal
          onClose={() => setOpenModal(null)}
          onConfirm={(blob) => handleAssetCreate("seal", blob)}
          user={props.user}
        />
      ) : openModal ? (
        <SignatureInitialsModal
          assetType={openModal}
          onClose={() => setOpenModal(null)}
          onCreate={(data) => handleSigInitialsCreate(openModal, data)}
        />
      ) : null}
    </>
  );
}

export default SignatureAndSeal;
