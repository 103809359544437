import { useState } from "react";
import { differenceInCalendarDays } from "date-fns";
import { FormattedMessage } from "react-intl";

import AlertMessage from "common/core/alert_message";

import { type ProofFrame_viewer_user_organization } from "./index.query.graphql";

const HIDE_DEFEND_TRIAL_BANNER_KEY = "proof:hide-defend-trial-banner";

type Organization = Pick<
  ProofFrame_viewer_user_organization,
  "defendTrial" | "defendRiskScoresTrial"
>;

type Props = {
  organization: Organization;
};

export function DefendTrialBanner({ organization }: Props) {
  const [hideBanner, setHideBanner] = useState(
    () => window.localStorage.getItem(HIDE_DEFEND_TRIAL_BANNER_KEY) === "true",
  );

  const defendTrialDaysLeft = differenceInCalendarDays(organization.defendTrial, Date.now());
  const riskScoresTrialDaysLeft = differenceInCalendarDays(
    organization.defendRiskScoresTrial,
    Date.now(),
  );

  const handleBannerClose = () => {
    setHideBanner(true);
    window.localStorage.setItem(HIDE_DEFEND_TRIAL_BANNER_KEY, "true");
  };

  if (hideBanner) {
    return null;
  }

  if (defendTrialDaysLeft >= 0) {
    return (
      <AlertMessage banner kind="subtle" centerText onClose={handleBannerClose}>
        <FormattedMessage
          id="8c4b0bd6-8229-467f-ac28-b30923774f38"
          defaultMessage="You have {daysLeft} {daysLeft, plural, one {day} other {days}} left in your Defend trial"
          values={{ daysLeft: defendTrialDaysLeft }}
        />
      </AlertMessage>
    );
  }

  if (riskScoresTrialDaysLeft >= 0) {
    return (
      <AlertMessage banner kind="subtle" centerText onClose={handleBannerClose}>
        <FormattedMessage
          id="e538a48c-922f-4edc-9c1c-899197ac3ae9"
          defaultMessage="You have {daysLeft} {daysLeft, plural, one {day} other {days}} left in your Risk Scores trial"
          values={{ daysLeft: riskScoresTrialDaysLeft }}
        />
      </AlertMessage>
    );
  }

  return null;
}
