import "common/form/form.scss";

import { useState, useEffect, useRef, type ReactElement } from "react";
import { reduxForm, Field, SubmissionError, type InjectedFormProps } from "redux-form";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import compose from "util/compose";
import FormGroup from "common/form/group";
import Button from "common/core/button";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedTextField } from "common/form/fields/text";
import DocumentUploaderManager from "common/document/uploader/manager";
import { interactingWithInput } from "common/form/util";
import { getFormValues, composeValidators } from "util/form";
import { onlyRequiresEsign } from "util/completion_requirements/completion_requirements_text";
import { validatePresence, validatePermalink } from "validators/form";
import { duplicatePermalink } from "errors/form";
import { DeprecatedMultiSelectInput } from "common/form/inputs/multi_select";
import { DeprecatedRadioGroup } from "common/form/inputs/radio_group";
import { DeprecatedRadioItem } from "common/form/inputs/radio_group/radio_item";
import { ROUTE as TEMPLATES_ROUTE } from "common/tools/document_templates";
import {
  TemplateTagsEnum,
  OrganizationDocumentTemplateState,
  OrganizationTypeEnum,
  ProcessingStates,
} from "graphql_globals";
import type { FormError } from "errors/util";
import type { useMutation } from "util/graphql";
import { EasylinkPreviewSection } from "common/easylink_template_preview";
import { isGraphQLError } from "util/graphql/query";
import { useMoveTools } from "util/feature_detection";
import {
  onReplaceDocument,
  type FileReplace,
} from "common/document/uploader/replace_document_uploader";

import type {
  TemplateRoot_template_OrganizationDocumentTemplate as Template,
  TemplateRoot_organization_Organization as Organization,
} from "../template_query.graphql";
import type {
  UpdateOrganizationDocumentTemplate_updateOrganizationDocumentTemplate_organizationDocumentTemplate as OrganizationDocumentTemplate,
  UpdateOrganizationDocumentTemplate_updateOrganizationDocumentTemplate_organizationDocumentTemplate_documentBundle_documents_edges as DocumentEdges,
  UpdateOrganizationDocumentTemplate_updateOrganizationDocumentTemplate_organizationDocumentTemplate_documentBundle_documents_edges_document as Document,
  UpdateOrganizationDocumentTemplateVariables,
  UpdateOrganizationDocumentTemplate as UpdateOrganizationDocumentTemplateMutation,
  UpdateOrganizationDocumentTemplate_updateOrganizationDocumentTemplate_errors as Errors,
} from "../update_organization_document_template_mutation.graphql";
import type {
  DeleteSelectedOrganizationDocumentTemplates as DeleteSelectedOrganizationDocumentTemplatesMutation,
  DeleteSelectedOrganizationDocumentTemplatesVariables,
} from "../delete_selected_organization_document_templates_mutation.graphql";
import { documentsInput } from "../util";
import ProcessingFailedModal from "./processing_failed_modal";
import TemplateDocumentUploader from "./uploader";

const TEMPLATE_TAG_OPTIONS = Object.keys(TemplateTagsEnum).map((tag) => {
  return {
    label: tag,
    value: tag,
  };
});

export type ChangeDocumentProps = {
  signingRequiresMeeting: boolean;
  signerCanAnnotate: boolean;
  witnessRequired: boolean;
  notarizationRequired: boolean;
  proofingRequired: boolean;
};

type EncompassTag = {
  label: TemplateTagsEnum;
  value: TemplateTagsEnum;
};

type FormValues = {
  displayName: string | null;
  hack: string;
  permalink: string | null;
  templateName: string;
};

type Props = {
  organization: Organization;
  template: Template;
  isMortgage?: boolean;
  updateTemplateMutateFn: ReturnType<
    typeof useMutation<
      UpdateOrganizationDocumentTemplateMutation,
      UpdateOrganizationDocumentTemplateVariables
    >
  >;
  deleteTemplateMutateFn: ReturnType<
    typeof useMutation<
      DeleteSelectedOrganizationDocumentTemplatesMutation,
      DeleteSelectedOrganizationDocumentTemplatesVariables
    >
  >;
};

type UpdateTemplateProps = FormValues & {
  quiet?: boolean;
  nextPage?: string;
};

type FormProps = InjectedFormProps<FormValues, Props>;
type InnerProps = Props & FormProps;

function OrganizationTemplateEditForm(props: InnerProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSaving, setIsSaving] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [processingDocumentIds, setProcessingDocumentIds] = useState<string[]>([]);
  const [showProcessingFailedModal, setShowProcessingFailedModal] = useState(false);
  const [documentToReplace, setDocumentToReplace] = useState<Document | null>(null);
  const [encompassTags, setEncompassTags] = useState(
    props.template.encompassTags.map((tag) => {
      return {
        label: tag,
        value: tag,
      };
    }),
  );
  const mounted = useRef(false);
  const moveTools = useMoveTools();

  useEffect(() => {
    mounted.current = true;
    const {
      template: { name, displayName, permalink, documentBundle },
      initialize,
    } = props;
    initialize({
      templateName: name,
      displayName,
      permalink,
      hack: "nonemptyfield", // to prevent the redux store values field from being cleared
    });

    // Set initial state if any docs are still processing.  This is necessary
    // for new templates because the first doc will have just been uploaded
    // when this component mounts.
    const processingDocumentIds = documentBundle.documents.edges
      .filter((edge) => edge.document.processingState === ProcessingStates.PENDING)
      .map((edge) => edge.document.id);
    setProcessingDocumentIds(processingDocumentIds);

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      mounted.current = false;
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function getBasePath() {
    const { pathname } = location;
    // BIZ-7111: Always use the new hardcoded tools path.
    return moveTools ? `tools/${TEMPLATES_ROUTE}` : pathname.split("/")[1];
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (interactingWithInput()) {
      return;
    }

    if (e.key === "Escape") {
      returnToList();
    }
  }

  function updateTemplateTools(args: FormValues) {
    return updateTemplate({ ...args, nextPage: "tools" });
  }

  function updateTemplateExit(args: FormValues) {
    return updateTemplate({ ...args, nextPage: "exit" });
  }

  function updateTemplate(args: UpdateTemplateProps) {
    const { templateName, displayName, permalink, quiet, nextPage = "prepare" } = args;
    const { template } = props;
    const { search } = location;
    const basePath = getBasePath();
    const { id, documentBundle, activationState } = template;

    setIsSaving(true);

    return props
      .updateTemplateMutateFn({
        variables: {
          input: {
            id,
            name: templateName,
            displayName,
            permalink,
            activationState,
            encompassTags: encompassTags.map((tag) => tag.value),
          },
        },
        optimisticResponse: {
          updateOrganizationDocumentTemplate: {
            __typename: "UpdateOrganizationDocumentTemplateMutationPayload",
            errors: null,
            organizationDocumentTemplate: {
              ...template,
              name: templateName,
              displayName,
              permalink,
            },
          },
        },
      })
      .then(() => {
        setIsSaving(false);
        if (quiet) {
          return;
        }
        if (nextPage === "tools") {
          navigate(`/${basePath}/${template.id}/tools/${search}`);
        } else if (nextPage === "exit") {
          const wasSearching = location.state;
          if (wasSearching) {
            returnToQuery();
          } else {
            returnToList();
          }
          // skip the annotation screen if no documents in the bundle
        } else if (!documentBundle.documents.edges.length) {
          navigate(`/${basePath}`);
        } else {
          navigate(`/${basePath}/${id}${search}`);
        }
      })
      .catch((error) => {
        setIsSaving(false);
        if (isGraphQLError(error) && error.graphQLErrors[0]?.message === "non_unique_permalink") {
          throw new SubmissionError<{ permalink: unknown }, FormError>({
            permalink: duplicatePermalink({ permalink }),
          });
        } else {
          throw error;
        }
      });
  }

  function updateActivationState(value: OrganizationDocumentTemplateState) {
    const { template } = props;
    props.updateTemplateMutateFn({
      variables: {
        input: {
          id: template.id,
          activationState: value,
          name: template.name,
          displayName: template.displayName,
          permalink: template.permalink,
          encompassTags: encompassTags.map((tag) => tag.value),
        },
      },
    });
  }

  function updateDocuments(newDocumentEdges: DocumentEdges[]) {
    const { template } = props;
    const { id, name, displayName, permalink, documentBundle, activationState } = template;

    setIsSaving(true);

    return props
      .updateTemplateMutateFn({
        variables: {
          input: {
            id,
            documents: documentsInput(newDocumentEdges),
            permalink,
            activationState,
            name,
            displayName,
          },
        },
        optimisticResponse: {
          updateOrganizationDocumentTemplate: {
            __typename: "UpdateOrganizationDocumentTemplateMutationPayload",
            errors: null,
            organizationDocumentTemplate: {
              ...template,
              documentBundle: {
                ...documentBundle,
                documents: {
                  __typename: "DocumentConnection",
                  edges: newDocumentEdges,
                },
              },
            },
          },
        },
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  function returnToList() {
    const { search } = location;
    const basePath = getBasePath();
    navigate(`/${basePath}${search}`);
  }

  function returnToQuery() {
    const { search } = location;
    const basePath = getBasePath();
    navigate(`/${basePath}${search}`);
  }

  function lowerCasePermalink(value: string) {
    return value.toLowerCase();
  }

  function deleteTemplate() {
    const {
      template: { id },
      deleteTemplateMutateFn,
    } = props;

    setIsCancelling(true);

    deleteTemplateMutateFn({
      variables: { input: { ids: [id] } },
      optimisticResponse: {
        deleteSelectedOrganizationDocumentTemplates: {
          organizationTemplateIds: [id],
        },
      },
    })
      .then(() => {
        setIsCancelling(false);
        returnToList();
      })
      .catch(() => setIsCancelling(false));
  }

  function addDocument(
    obj: { s3Key: string; filename: string },
    onSuccess: (arg: OrganizationDocumentTemplate) => void,
    onFailure: (errors: Errors[]) => void,
  ) {
    const {
      template: {
        id,
        permalink,
        documentBundle: { documents },
        name,
        displayName,
        activationState,
      },
    } = props;

    setIsSaving(true);

    const originalDocuments = documentsInput(documents.edges);
    const newDocument = {
      fileHandle: obj.s3Key,
      name: obj.filename,
      customerCanAnnotate: true,
      witnessRequired: false,
      notarizationRequired: true,
      proofingRequired: false,
      esign: false,
    };
    const newDocuments = [...originalDocuments, newDocument];

    return props
      .updateTemplateMutateFn({
        variables: {
          input: {
            id,
            documents: newDocuments,
            permalink,
            name,
            displayName,
            activationState,
          },
        },
      })
      .then((response) => {
        setIsSaving(false);
        const { organizationDocumentTemplate, errors } =
          response.data?.updateOrganizationDocumentTemplate || {};
        if (errors) {
          onFailure(errors);
        } else {
          onSuccess(organizationDocumentTemplate!);
        }
      });
  }

  function handleDocumentProcessingStarted(documentId: string) {
    if (!mounted.current) {
      return; // callback is not needed if unmounted
    }
    setProcessingDocumentIds((prev) => prev.concat(documentId));
  }

  function handleDocumentProcessingDone(documentId: string) {
    if (!mounted.current) {
      return; // callback is not needed if unmounted
    }
    setProcessingDocumentIds((prev) => prev.filter((id) => id !== documentId));
  }

  function handleDocumentProcessingFailed(documentId: string) {
    if (!mounted.current) {
      return; // callback is not needed if unmounted
    }

    // Backend does not delete the doc from the bundle automatically, so we do it here
    const {
      template: {
        documentBundle: { documents },
      },
    } = props;
    const docEdge = documents.edges.find((edge) => edge.document.id === documentId);
    if (docEdge) {
      handleDeleteDocument(docEdge.document);
    }

    setProcessingDocumentIds((prev) => prev.filter((id) => id !== documentId));
    setShowProcessingFailedModal(true); // Inform the user that the doc is not processable
  }

  function closeProcessingFailedModal() {
    setShowProcessingFailedModal(false);
  }

  function handleChangeDocumentProperties(
    propValues: ChangeDocumentProps,
    document: Document | null,
  ) {
    const {
      template: {
        documentBundle: { documents },
      },
    } = props;
    if (document) {
      const documentId = document.id;
      const newDocumentEdges = documents.edges.map((edge) => {
        return edge.document.id === documentId
          ? { ...edge, document: { ...edge.document, ...propValues } }
          : edge;
      });
      updateDocuments(newDocumentEdges);
    }
  }

  function handleReplaceDocument(newDocument: FileReplace) {
    const {
      template: {
        documentBundle: { documents },
      },
    } = props;

    const replaceDocumentFn = async () => {
      if (!documentToReplace) {
        return null;
      }

      const newDocumentEdges = documents.edges.map((edge) => {
        return edge.document.id === documentToReplace.id
          ? { ...edge, document: { ...edge.document, documentToCopyId: newDocument.id } }
          : edge;
      });

      const response = await updateDocuments(newDocumentEdges);

      const { errors } = response.data?.updateOrganizationDocumentTemplate || {};

      return errors;
    };

    return onReplaceDocument<Errors>(replaceDocumentFn, newDocument.name);
  }

  function handleDeleteDocument(document: Document) {
    const {
      template: {
        documentBundle: { documents },
      },
    } = props;
    const documentId = document.id;
    const newDocumentEdges = documents.edges.filter((edge) => {
      return edge.document.id !== documentId;
    });
    updateDocuments(newDocumentEdges);
  }

  function handleRenameDocument(document: Document, newName: string) {
    const {
      template: {
        documentBundle: { documents },
      },
    } = props;
    const documentId = document.id;
    const newDocumentEdges = documents.edges.map((edge) => {
      return edge.document.id === documentId
        ? { ...edge, document: { ...edge.document, name: newName } }
        : edge;
    });
    updateDocuments(newDocumentEdges);
  }

  const { organization, template, handleSubmit, isMortgage } = props;
  const documentBundle = template.documentBundle;
  const documents = documentBundle.documents.edges.map((edge) => edge.document);
  const isNewTemplate = location.pathname.endsWith("/new");
  const canRequireMeeting =
    organization.organizationType === OrganizationTypeEnum.LENDER ||
    organization.organizationType === OrganizationTypeEnum.TITLE_AGENCY;

  const isProcessing = processingDocumentIds.length > 0;
  const isEsign = onlyRequiresEsign(template.documentBundle.completionRequirements);
  const templateStates = [
    OrganizationDocumentTemplateState.ACTIVE,
    OrganizationDocumentTemplateState.DRAFT,
    OrganizationDocumentTemplateState.DISABLED,
  ];

  const cancelButtonClicked = () => {
    const wasSearching = location.state;
    if (isNewTemplate) {
      deleteTemplate();
    } else if (wasSearching) {
      returnToQuery();
    } else {
      returnToList();
    }
  };

  // HACK ALERT
  // This extra hidden field is necessary because the redux form values key disappears from
  // the redux store if you have a single field form and the value is cleared.
  // If there are no values, validation is not performed, so no error will be shown even though
  // the field is required. Adding an extra hidden field prevents the values key from disappearing.
  // This is used in conjunction with initializing a value for this field, above.

  // NOTE: this seems incredibly incorrect. if you come by in the future,
  // you can probably fix this with the our new Field components
  const emptyField = <Field name="hack" component={() => null} />;

  return (
    <form className="OrganizationTemplateEdit--Form">
      {showProcessingFailedModal && <ProcessingFailedModal onClose={closeProcessingFailedModal} />}

      {template.preset && (
        <Button
          onClick={handleSubmit(updateTemplateTools)}
          variant="secondary"
          buttonColor="action"
          className="OrganizationTemplateEdit--Form--Button"
          automationId="templates-developer-tools"
        >
          <FormattedMessage
            id="ef057505-3d3d-4dea-b233-f7b71bcaddbf"
            defaultMessage="Template settings"
          />
        </Button>
      )}

      {template.preset && (
        <div className="OrganizationTemplateTools--Header--Toggle">
          <DeprecatedRadioGroup
            selectedValue={template.activationState}
            onChange={updateActivationState}
          >
            {templateStates.map((state) => (
              <DeprecatedRadioItem key={state} value={state} label={state} />
            ))}
          </DeprecatedRadioGroup>
        </div>
      )}

      <FormGroup
        fields={["templateName"]}
        disableFormRowStyle
        className="OrganizationTemplateEdit--Form--Group"
      >
        {emptyField}
        <DeprecatedTextField
          name="templateName"
          useStyledInput
          placeholder="e.g. Power of Attorney for notarization"
          label="Template name"
          data-automation-id="template-name-field"
        />
        <FormGroupErrors fields={["templateName"]} />
      </FormGroup>

      {template.preset && (
        <FormGroup
          fields={["displayName"]}
          disableFormRowStyle
          className="OrganizationTemplateEdit--Form--Group"
        >
          <DeprecatedTextField
            name="displayName"
            useStyledInput
            label="Display name (optional)"
            data-automation-id="template-display-name-field"
          />
        </FormGroup>
      )}

      {!template.preset && (
        <FormGroup
          fields={["permalink"]}
          disableFormRowStyle
          className="OrganizationTemplateEdit--Form--Group"
        >
          <DeprecatedTextField
            id="permalink"
            name="permalink"
            useStyledInput
            label="Permalink for API use (optional)"
            normalize={lowerCasePermalink}
            data-automation-id="permalink-field"
          />

          <FormGroupErrors fields={["permalink"]} />
        </FormGroup>
      )}

      {template.preset && (
        <>
          <div className="OrganizationTemplateEdit--TemplateTags">
            <DeprecatedMultiSelectInput
              items={TEMPLATE_TAG_OPTIONS}
              onChange={(tags: EncompassTag[]) => {
                setEncompassTags(tags);
              }}
              value={encompassTags}
              label="Template tags"
              data-automation-id="save-template-tags"
            />
          </div>
        </>
      )}

      <div className="OrganizationTemplateEdit--Uploader">
        <DocumentUploaderManager
          documents={documents}
          documentBundleId={documentBundle.id}
          onChangeDocumentProperties={handleChangeDocumentProperties}
          onDeleteDocuments={handleDeleteDocument}
          setDocumentToReplace={setDocumentToReplace}
          onReplaceDocument={handleReplaceDocument}
          onRenameDocument={handleRenameDocument}
          showWitnessRequired={!isEsign}
          onDocumentProcessingStarted={handleDocumentProcessingStarted}
          onDocumentProcessingDone={handleDocumentProcessingDone}
          onDocumentProcessingFailed={handleDocumentProcessingFailed}
          disableClick
          hideAnnotateButton
          canRequireEsign={!canRequireMeeting}
          canRequireMeeting={canRequireMeeting}
          organization={organization}
          isMortgage={isMortgage}
          isTemplateEditing
          templateId={template.id}
        />
      </div>

      <div className="OrganizationTemplateEdit--Uploader--Button--Container">
        <TemplateDocumentUploader
          title={
            <FormattedMessage
              id="9744be99-5c6a-4100-8858-37ad206853f6"
              defaultMessage="Add document"
            />
          }
          buttonClassName="OrganizationTemplateEdit--Uploader--Button"
          buttonStyleProps={{
            withIcon: { name: "add", placement: "left" },
            children: "Add document",
          }}
          onChangeDocument={addDocument}
        />
      </div>

      {template.preset && (
        <div className="OrganizationTemplateInformationalText">
          {
            <span>
              To place signer fields on your document template, choose “Prepare document.”
              Otherwise, "Save and exit."
            </span>
          }
        </div>
      )}

      {template.easylinks.length > 0 && <EasylinkPreviewSection easylinks={template.easylinks} />}

      <div className="OrganizationTemplateEdit--options">
        <Button
          automationId="cancel-button"
          isLoading={isCancelling}
          disabled={isCancelling}
          onClick={cancelButtonClicked}
          variant="tertiary"
          buttonColor="action"
        >
          <FormattedMessage id="75b26d71-b425-4205-9d16-56bb830de3b5" defaultMessage="Cancel" />
        </Button>

        <Button
          onClick={handleSubmit(updateTemplateExit)}
          isLoading={isSaving || isCancelling}
          disabled={isSaving || isCancelling}
          automationId="save-exit-button"
          className="OrganizationTemplateEdit--Uploader--SaveExitButton"
          variant="secondary"
          buttonColor="action"
        >
          <FormattedMessage
            id="a5823a1b-7f91-48e0-8d3c-c49d2200d8e5"
            defaultMessage="Save and exit"
          />
        </Button>

        <Button
          onClick={handleSubmit(updateTemplate)}
          isLoading={isSaving || isProcessing}
          disabled={isSaving || isProcessing}
          automationId="save-template-button"
          className="OrganizationTemplateEdit--Uploader--NextButton"
          variant="primary"
          buttonColor="action"
        >
          <FormattedMessage
            id="a4823a1b-7f91-48e0-8d3c-c49d2200d8e5"
            defaultMessage="Prepare document"
          />
        </Button>
      </div>
    </form>
  );
}

function validate(values: FormValues) {
  return composeValidators(
    validatePresence({ field: "templateName", label: "Template Name" }),
    validatePermalink({ field: "permalink" }),
  )(values);
}

export default compose(
  reduxForm({
    form: "smbEditTemplate",
    validate,
  }),
  getFormValues("smbEditTemplate"),
)(OrganizationTemplateEditForm) as unknown as (props: Props) => ReactElement;
