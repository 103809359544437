import { useEffect, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";

import {
  NotaryDocumentTypes,
  ValidationRequirements,
  NotaryProfileInvalidFields as InvalidFields,
} from "graphql_globals";
import { composeValidators } from "util/form";
import { normalizeToNumber } from "util/normalize";
import { splitDate } from "util/date";
import { validatePresence, validateIf, validateFutureDay } from "validators/form";
import { DeprecatedSubForm } from "common/form/sub_form";
import { DeprecatedSubFormSection } from "common/form/sub_form/section";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedMonthField } from "common/form/fields/month";
import { NotaryProfileWizardAssetUploader } from "common/notary/profile_wizard/upload";

import type {
  NotaryProfileWizardStateEducation as User,
  NotaryProfileWizardStateEducation_notaryProfile as NotaryProfile,
} from "./index_fragment.graphql";
import {
  findDocumentLabel,
  initializeNotaryDocumentField,
  useChangeFileCallback,
  type FileInfo,
  type SubmitType,
} from "../section_utils";

export const EDUCATION_PATH = "education";

type FormValues = {
  educationExpirationDay: string;
  educationExpirationMonth: string;
  educationExpirationYear: string;
  educationCompletionKey: FileInfo;
};
type Input = {
  educationExpirationDate: string | null;
  notaryDocuments:
    | { keys: string[]; name: string | undefined; documentType: NotaryDocumentTypes }[]
    | null;
};
type Props = {
  user: User;
  proofRequired: boolean;
  expirationDateRequired: boolean;
  onNext: (input: Input) => void;
  renderFooter: (handleSubmit: () => SubmitType) => ReactNode;
};
type InnerProps = InjectedFormProps<FormValues, Props> & Props;

const PROOF_EDU_LABEL = (
  <FormattedMessage
    id="a01407d3-5d42-4e9e-82e6-a89612009fd3"
    defaultMessage="Proof of Completion"
  />
);

type StateEducationType =
  | {
      id: "StateEducation";
      proofRequired: boolean;
      expirationDateRequired: boolean;
      completed: boolean;
      route: typeof EDUCATION_PATH;
    }
  | false;

export function stateEducationSection(
  lookup: Set<ValidationRequirements>,
  notaryProfile: NotaryProfile,
): StateEducationType {
  const proofRequired = lookup.has(ValidationRequirements.EDUCATION_COMPLETED_PROOF);
  const expirationDateRequired = lookup.has(ValidationRequirements.EDUCATION_EXPIRATION_DATE);
  const fields = [
    InvalidFields.INVALID_EDUCATION_EXPIRATION_DATE,
    InvalidFields.INVALID_EDUCATION_COMPLETION_DOCUMENT,
  ];
  const completed = !fields.some((field) => notaryProfile.validation.invalidFields.includes(field));
  return (
    (expirationDateRequired || proofRequired) && {
      id: "StateEducation",
      expirationDateRequired,
      proofRequired,
      completed,
      route: EDUCATION_PATH,
    }
  );
}

function StateRequiredEducation({
  user,
  proofRequired,
  expirationDateRequired,
  initialize,
  change,
  handleSubmit,
  onNext,
  renderFooter,
}: InnerProps) {
  const notaryProfile = user.notaryProfile!;
  const serializeForm = (fv: FormValues) => {
    return onNext({
      educationExpirationDate: expirationDateRequired
        ? `${fv.educationExpirationYear}-${fv.educationExpirationMonth}-${fv.educationExpirationDay}`
        : null,
      notaryDocuments:
        proofRequired && typeof fv.educationCompletionKey?.key === "string"
          ? [
              {
                keys: [fv.educationCompletionKey.key],
                name: fv.educationCompletionKey.file?.name,
                documentType: NotaryDocumentTypes.EDUCATION_COMPLETION,
              },
            ]
          : null,
    });
  };
  const handleEducationKeyChange = useChangeFileCallback("educationCompletionKey", change);

  useEffect(() => {
    const { educationExpirationDate, notaryDocuments } = notaryProfile;
    const [educationExpirationYear, educationExpirationMonth, educationExpirationDay] =
      splitDate(educationExpirationDate);

    initialize({
      educationExpirationDay,
      educationExpirationYear,
      educationExpirationMonth,
      educationCompletionKey: initializeNotaryDocumentField(
        notaryDocuments,
        NotaryDocumentTypes.EDUCATION_COMPLETION,
      ),
    });
  }, []);
  return (
    <>
      <div>
        <FormattedMessage
          id="d2051393-75af-43e1-b5e0-a3759c29f658"
          defaultMessage="State Required Education"
          tagName="h3"
        />
        <FormattedMessage
          id="2ceec158-9260-49ca-a958-0ce5d482c663"
          defaultMessage="Please provide the expiration date of your most recently completed state required education."
          tagName="p"
        />

        <DeprecatedSubForm>
          {expirationDateRequired && (
            <DeprecatedSubFormSection>
              <FormGroup
                disableFormRowStyle
                fields={[
                  "educationExpirationYear",
                  "educationExpirationMonth",
                  "educationExpirationDay",
                ]}
              >
                <FormattedMessage
                  id="e5381e32-5e37-4711-aa45-0174fbbd25c5"
                  defaultMessage="Education Expiration Date"
                  tagName="label"
                />
                <DeprecatedMultipartRow>
                  <DeprecatedMultipartColumn width={6}>
                    <DeprecatedMonthField
                      name="educationExpirationMonth"
                      automationId="educationExpirationMonth"
                      useStyledInput
                      searchable={false}
                      clearable={false}
                    />
                  </DeprecatedMultipartColumn>
                  <DeprecatedMultipartColumn width={2}>
                    <DeprecatedTextField
                      name="educationExpirationDay"
                      automationId="educationExpirationDay"
                      placeholder="DD"
                      normalize={normalizeToNumber}
                      maxLength="2"
                      useStyledInput
                    />
                  </DeprecatedMultipartColumn>
                  <DeprecatedMultipartColumn width={4}>
                    <DeprecatedTextField
                      name="educationExpirationYear"
                      automationId="educationExpirationYear"
                      placeholder="YYYY"
                      normalize={normalizeToNumber}
                      maxLength="4"
                      useStyledInput
                    />
                  </DeprecatedMultipartColumn>
                </DeprecatedMultipartRow>

                <FormGroupErrors
                  fields={[
                    "educationExpirationDay",
                    "educationExpirationMonth",
                    "educationExpirationYear",
                  ]}
                />
              </FormGroup>
            </DeprecatedSubFormSection>
          )}

          {proofRequired && (
            <DeprecatedSubFormSection>
              <FormattedMessage
                id="56c42f15-f201-4f3a-9817-2440737aae1c"
                defaultMessage="Upload a screenshot, email, or other documentation as proof of completion of that education."
                tagName="p"
              />
              <NotaryProfileWizardAssetUploader
                persistedValue={findDocumentLabel(
                  notaryProfile.notaryDocuments,
                  NotaryDocumentTypes.EDUCATION_COMPLETION,
                  PROOF_EDU_LABEL,
                )}
                onChange={handleEducationKeyChange}
              />
              <FormGroupErrors fields={["educationCompletionKey"]} />
            </DeprecatedSubFormSection>
          )}
        </DeprecatedSubForm>
      </div>
      {renderFooter(handleSubmit(serializeForm))}
    </>
  );
}

export default reduxForm<FormValues, Props>({
  form: "notaryProfileWizardStateEducation",
  validate: (values, props) =>
    composeValidators(
      validateIf({
        field: "educationCompletionKey",
        condition: () => props.proofRequired,
        validation: validatePresence({
          field: "educationCompletionKey",
          label: "Proof of completion",
        }),
      }),
      validateIf({
        field: "educationExpirationDay",
        condition: () => props.expirationDateRequired,
        validation: validatePresence({
          field: "educationExpirationDay",
          label: "Education expiration day",
        }),
      }),
      validateIf({
        field: "educationExpirationMonth",
        condition: () => props.expirationDateRequired,
        validation: validatePresence({
          field: "educationExpirationMonth",
          label: "Education expiration month",
        }),
      }),
      validateIf({
        field: "educationExpirationYear",
        condition: () => props.expirationDateRequired,
        validation: validateFutureDay({
          field: "educationExpirationYear",
          label: "Education expiration",
          monthField: "educationExpirationMonth",
          dayField: "educationExpirationDay",
          yearField: "educationExpirationYear",
        }),
      }),
    )(values),
})(StateRequiredEducation);
