import type { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import LoadingIndicator from "common/core/loading_indicator";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery } from "util/graphql";
import Tab from "common/core/tabs/tab";
import { Card, CardText } from "common/core/card";
import { isNotaryNST, isNotaryODN } from "common/notary/capacity";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import PayoutOptionsSection from "common/notary/profile_wizard/section/payout";
import { SettingsTitle } from "common/settingsv2/common";
import { TabIncompleteIcon } from "common/settingsv2/sidebar_settings/notary/incomplete_icon";

import BillingSettingsQuery from "../billing_query.graphql";
import { PaidBy } from "./paid_by";
import { EasylinksPaidBy } from "./easylinks_paid_by";

export const BILLING_PAYMENT_SETTINGS_ROUTE = "payment-settings";

export function PaymentSettingsTab({
  notaryMissingPayment = false,
}: {
  notaryMissingPayment?: boolean;
}) {
  return (
    <Tab to={BILLING_PAYMENT_SETTINGS_ROUTE}>
      <FormattedMessage
        id="a13d008d-5000-488e-b2d0-4c46580ac77a"
        defaultMessage="Payment Settings"
      />
      {notaryMissingPayment && <TabIncompleteIcon />}
    </Tab>
  );
}

type Props = {
  extendedSettings?: ReactNode;
  // Should only be passed in from business admin settings since
  // ODNs/NSTs are admin's of their orgs and they're the only ones
  // that would access their notary payment options in org settings.
  showNotaryPaymentSettings?: boolean;
};

export function PaymentSettings({ extendedSettings, showNotaryPaymentSettings }: Props) {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsBillingPaymentSettings),
  });
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(BillingSettingsQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data?.node;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}`);
  }
  const user = data!.viewer.user!;
  const notaryProfile = user.notaryProfile;
  const isODNNotary = isNotaryODN(notaryProfile);
  const isNSTNotary = isNotaryNST(notaryProfile);

  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="1ddaf316-5f8c-42fd-9a03-c0f72c4c510e"
          defaultMessage="Payment Settings"
        />
        {isODNNotary ? (
          <CardText>
            <FormattedMessage
              id="11b42151-dc61-46da-a06f-6a0b9acf43d3"
              defaultMessage="In your state, you must collect signer payments for transactions you create outside of Proof. Signers will not be charged by Proof. Payouts for completing on-demand transactions can be collected via a Stripe Connect account."
            />
          </CardText>
        ) : isNSTNotary ? (
          <CardText>
            <FormattedMessage
              id="d870f8f8-7195-4c8c-a8ef-c5b3514cf1c1"
              defaultMessage="You can collect signer payments via Proof. When signers pay on Proof, the notarization fee is deposited directly into your Stripe Payout account. Alternatively, you can choose to collect signer payments outside of Proof. You must set default fees regardless of how the signer will pay. You can always change the signer fees for individual transactions."
            />
          </CardText>
        ) : null}
      </SettingsTitle>
      {showNotaryPaymentSettings && (isODNNotary || isNSTNotary) ? (
        <Card className="NotaryProfileWizardStep">
          <PayoutOptionsSection user={user} inSettings />
        </Card>
      ) : (
        <>
          <PaidBy notaryProfile={notaryProfile} organization={organization} />
          {extendedSettings}
          <EasylinksPaidBy />
        </>
      )}
    </>
  );
}
