import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { clearSubmitErrors, SubmissionError } from "redux-form";
import { isPast } from "date-fns";

import { DeprecatedPhoneNumberField } from "common/form/fields/phone/number";
import { ValidationRequirements } from "graphql_globals";
import { DeprecatedSubForm } from "common/form/sub_form";
import { DeprecatedSubFormSection } from "common/form/sub_form/section";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedMonthField } from "common/form/fields/month";
import { normalizeToNumber } from "util/normalize";
import Button from "common/core/button";
import { useId } from "util/html";
import { splitDate } from "util/date";
import type { FormError } from "errors/util";
import { customMessage } from "errors/form";
import { pushNotification } from "common/core/notification_center/actions";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { segmentTrack } from "util/segment";
import { Badge } from "common/core/badge";
import { isNotaryODN } from "common/notary/capacity";
import AlertMessage from "common/core/alert_message";

import UpdateNnaInformationMutation from "./update_nna_information_mutation.graphql";
import Styles from "./nna.module.scss";
import { getNnaErrorMessage } from "../section_utils";

type NNASectionComponentProps = {
  /** if falsy, there will be no button */
  refreshNnaData?: () => Promise<unknown>;
  notaryProfile: null | { requirements: ValidationRequirements[] };
  onCheatClick: () => void;
};

export const NNA_EXPIRED_ERROR_MESSAGE = (
  <FormattedMessage
    id="78cefc6f-89d5-4056-a1db-73199f5fbdd3"
    defaultMessage="Sorry, either your course certification or background check has expired. Please confirm your information on your NNA profile"
  />
);

const NNA_REFRESH_NO_CHANGE_ERROR_MESSAGE = (
  <FormattedMessage
    id="644ac855-3aea-4662-b321-ef94f7be58dc"
    defaultMessage="Your NNA profile looks the same – please make sure you’ve updated these dates in your NNA profile."
  />
);

export const NNA_MISSING_EXAM_OR_BACKGROUND_CHECK = (
  <FormattedMessage
    id="608bd246-69b2-4318-bb0c-cf794fd11407"
    defaultMessage="You must have a valid background check and have completed the NSA exam within the last year. Please confirm your NNA profile contains completion dates for those two items."
  />
);

type RefreshSubmitInput = {
  change: (key: string, newValue: string) => void;
  formName: string;
  notaryProfileId: string;
  notaryBackgroundCheckExpiry: string | null;
  notaryNnaTrainingExpiration: string | null;
};
type RefreshFormValues = { nnaId: string | null };

export function useRefreshSubmit({
  change,
  formName,
  notaryProfileId,
  notaryBackgroundCheckExpiry,
  notaryNnaTrainingExpiration,
}: RefreshSubmitInput) {
  const dispatch = useDispatch();
  const updateNnaInformationMutation = useMutation(UpdateNnaInformationMutation);

  return async function submitNnaRefresh(fv: RefreshFormValues) {
    dispatch(clearSubmitErrors(formName));
    segmentTrack("NNA_REFRESH", {
      notary_profile_id: notaryProfileId,
      portal: "business",
    });
    return updateNnaInformationMutation({
      variables: {
        input: { nnaId: String(fv.nnaId), notaryProfileId, refresh: true },
      },
    })
      .then((result) => {
        const { backgroundCheckExpiry, nnaTrainingExpiry } =
          result.data!.updateNotaryNnaInformation!.notaryProfile;

        const [
          backgroundCheckExpirationYear,
          backgroundCheckExpirationMonth,
          backgroundCheckExpirationDay,
        ] = splitDate(backgroundCheckExpiry);

        change("backgroundCheckExpirationYear", backgroundCheckExpirationYear);
        change("backgroundCheckExpirationMonth", backgroundCheckExpirationMonth);
        change("backgroundCheckExpirationDay", backgroundCheckExpirationDay);

        const [nnaTrainingExpirationYear, nnaTrainingExpirationMonth, nnaTrainingExpirationDay] =
          splitDate(nnaTrainingExpiry);

        change("nnaTrainingExpirationYear", nnaTrainingExpirationYear);
        change("nnaTrainingExpirationMonth", nnaTrainingExpirationMonth);
        change("nnaTrainingExpirationDay", nnaTrainingExpirationDay);

        if (!backgroundCheckExpiry || !nnaTrainingExpiry) {
          throw new SubmissionError<RefreshFormValues, FormError>({
            nnaId: customMessage({
              message: NNA_MISSING_EXAM_OR_BACKGROUND_CHECK,
            }),
          });
        } else if (
          backgroundCheckExpiry === notaryBackgroundCheckExpiry &&
          nnaTrainingExpiry === notaryNnaTrainingExpiration
        ) {
          throw new SubmissionError<RefreshFormValues, FormError>({
            nnaId: customMessage({
              message: NNA_REFRESH_NO_CHANGE_ERROR_MESSAGE,
            }),
          });
        } else if (isPast(new Date(backgroundCheckExpiry)) || isPast(new Date(nnaTrainingExpiry))) {
          throw new SubmissionError<RefreshFormValues, FormError>({
            nnaId: customMessage({
              message: NNA_EXPIRED_ERROR_MESSAGE,
            }),
          });
        }

        pushNotification({
          type: "DEFAULT",
          position: "bottomCenter",
          message: (
            <FormattedMessage
              id="7e87b588-5eb4-4e4e-8428-3a39a113e48d"
              defaultMessage="We’ve updated your information based on your NNA profile."
            />
          ),
        });
      })
      .catch((error) => {
        const nnaErrorMessage = isGraphQLError(error) && getNnaErrorMessage(error.message);
        if (nnaErrorMessage) {
          throw new SubmissionError<RefreshFormValues, FormError>({
            nnaId: customMessage({
              message: nnaErrorMessage,
            }),
          });
        }
        throw error;
      });
  };
}

export function hasNNASection(
  notaryProfile: Parameters<typeof isNotaryODN>[0] & {
    requirements: ValidationRequirements[];
  },
): boolean {
  return (
    isNotaryODN(notaryProfile) ||
    notaryProfile.requirements.includes(ValidationRequirements.NNA_EXPIRATION)
  );
}

export function NNASection(props: NNASectionComponentProps) {
  const { refreshNnaData, notaryProfile, onCheatClick } = props;
  const [refreshing, setRefreshing] = useState(false);
  const nnaFieldId = useId();
  const nnaPhoneNumberId = useId();
  const nnaTestMode = notaryProfile?.requirements.includes(
    ValidationRequirements.NNA_CHEAT_ENABLED,
  );
  return (
    <>
      <h3 className={Styles.header}>
        <FormattedMessage
          id="ea63b465-bbc0-459f-8f07-aaeca48323de"
          defaultMessage="NNA Information"
        />
        <Badge kind="infoSubtle">
          <FormattedMessage
            id="b0d012a6-ccb6-492a-ad3e-cca750675834"
            defaultMessage="Recommended"
          />
        </Badge>
      </h3>
      <FormattedMessage
        id="b3b8d1f4-62bd-4fb3-8fb9-38a797729e9b"
        defaultMessage="We check with the NNA to verify your completion of the required notarial training and that an unexpired background screening is on file. This is a requirement to take on-demand calls generated by the Notarize Network."
        tagName="p"
      />
      <DeprecatedSubForm>
        <DeprecatedSubFormSection fullWidth>
          <div className={Styles.nnaIdSection}>
            <label htmlFor={nnaFieldId}>
              <FormattedMessage
                id="9999fb58-423f-4b83-89b0-c95780faec70"
                defaultMessage="NNA ID Number"
              />
            </label>
            <div />
            <div>
              <label htmlFor={nnaPhoneNumberId}>
                <FormattedMessage
                  id="82edc8e5-31c6-45f7-8940-f70d78bbdfc9"
                  defaultMessage="NNA Phone Number"
                />
              </label>
            </div>

            <DeprecatedTextField
              name="nnaId"
              id={nnaFieldId}
              useStyledInput
              normalize={normalizeToNumber}
              automationId="nnaId"
            />
            {refreshNnaData ? (
              <Button
                isLoading={refreshing}
                buttonColor="action"
                onClick={() => {
                  setRefreshing(true);
                  refreshNnaData().finally(() => setRefreshing(false));
                }}
                buttonSize="large"
                variant="primary"
              >
                <FormattedMessage
                  id="901ddada-8d2d-4450-a1b6-352bfdbb497f"
                  defaultMessage="Refresh dates"
                />
              </Button>
            ) : (
              <div />
            )}
            <div>
              <DeprecatedPhoneNumberField
                id={nnaPhoneNumberId}
                automationId="nna-phone-field"
                name="phone"
                isInternational={false}
                autoComplete="tel-national"
                useStyledInput
              />
              <FormGroupErrors fields={["phone"]} />
            </div>

            {nnaTestMode && (
              <AlertMessage className={Styles.alertMessage} kind="info" centerText>
                <div className={Styles.testBoxContainer}>
                  <div className={Styles.testBoxText}>
                    <FormattedMessage
                      id="b8a02db9-ca5b-4786-aa52-0dc82ccf227f"
                      defaultMessage="Skip using a number. Visible in test environments only."
                    />
                  </div>
                  <Button buttonColor="action" variant="primary" onClick={onCheatClick}>
                    <FormattedMessage
                      id="dbd74569-3445-4926-85dc-e6defb23e69a"
                      defaultMessage="Use test number"
                    />
                  </Button>
                </div>
              </AlertMessage>
            )}
          </div>
          <FormGroupErrors fields={["nnaId"]} />
        </DeprecatedSubFormSection>
        <DeprecatedSubFormSection fullWidth>
          <FormGroup
            disableFormRowStyle
            fields={[
              "nnaTrainingExpirationYear",
              "nnaTrainingExpirationMonth",
              "nnaTrainingExpirationDay",
            ]}
          >
            <FormattedMessage
              id="8f4d2b00-ab66-400e-a4bc-c6e081597f1a"
              defaultMessage="Notary Signing Agent (NSA) Exam Expiration Date (1 year from completion date)"
              tagName="label"
            />
            <DeprecatedMultipartRow>
              <DeprecatedMultipartColumn width={6}>
                <DeprecatedMonthField
                  name="nnaTrainingExpirationMonth"
                  automationId="nnaExpiryMonth"
                  useStyledInput
                  searchable={false}
                  clearable={false}
                  disabled
                />
              </DeprecatedMultipartColumn>
              <DeprecatedMultipartColumn width={2}>
                <DeprecatedTextField
                  name="nnaTrainingExpirationDay"
                  automationId="nnaExpiryDay"
                  placeholder="DD"
                  normalize={normalizeToNumber}
                  maxLength="2"
                  useStyledInput
                  disabled
                />
              </DeprecatedMultipartColumn>
              <DeprecatedMultipartColumn width={4}>
                <DeprecatedTextField
                  name="nnaTrainingExpirationYear"
                  automationId="nnaExpiryYear"
                  placeholder="YYYY"
                  normalize={normalizeToNumber}
                  maxLength="4"
                  useStyledInput
                  disabled
                />
              </DeprecatedMultipartColumn>
            </DeprecatedMultipartRow>

            <FormGroupErrors
              fields={[
                "nnaTrainingExpirationDay",
                "nnaTrainingExpirationMonth",
                "nnaTrainingExpirationYear",
              ]}
            />
          </FormGroup>
        </DeprecatedSubFormSection>

        <DeprecatedSubFormSection fullWidth>
          <FormGroup
            disableFormRowStyle
            fields={[
              "backgroundCheckExpirationYear",
              "backgroundCheckExpirationMonth",
              "backgroundCheckExpirationDay",
            ]}
          >
            <FormattedMessage
              id="071cbe63-6e00-4d2a-9a0c-7a6a1b120159"
              defaultMessage="Background Check Expiration Date (1 year from completion date)"
              tagName="label"
            />
            <DeprecatedMultipartRow>
              <DeprecatedMultipartColumn width={6}>
                <DeprecatedMonthField
                  name="backgroundCheckExpirationMonth"
                  automationId="backgroundExpiryMonth"
                  useStyledInput
                  searchable={false}
                  clearable={false}
                  disabled
                />
              </DeprecatedMultipartColumn>
              <DeprecatedMultipartColumn width={2}>
                <DeprecatedTextField
                  name="backgroundCheckExpirationDay"
                  automationId="backgroundExpiryDay"
                  placeholder="DD"
                  normalize={normalizeToNumber}
                  maxLength="2"
                  useStyledInput
                  disabled
                />
              </DeprecatedMultipartColumn>
              <DeprecatedMultipartColumn width={4}>
                <DeprecatedTextField
                  name="backgroundCheckExpirationYear"
                  automationId="backgroundExpiryYear"
                  placeholder="YYYY"
                  normalize={normalizeToNumber}
                  maxLength="4"
                  useStyledInput
                  disabled
                />
              </DeprecatedMultipartColumn>
            </DeprecatedMultipartRow>

            <FormGroupErrors
              fields={[
                "backgroundCheckExpirationDay",
                "backgroundCheckExpirationMonth",
                "backgroundCheckExpirationYear",
              ]}
            />
          </FormGroup>
        </DeprecatedSubFormSection>
      </DeprecatedSubForm>
    </>
  );
}
