import { useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import UpsertDocumentBundleInstructionMutation from "common/transactions/graphql/mutations/upsert_document_bundle_instruction_mutation.graphql";
import DeleteDocumentBundleInstructionMutation from "common/transactions/graphql/mutations/delete_document_bundle_instruction_mutation.graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery, useMutation } from "util/graphql";
import { Card, CardHeading, CardText } from "common/core/card";
import Button from "common/core/button";
import LoadingIndicator from "common/core/loading_indicator";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";

import NotaryInstructionQuery, {
  type NotaryInstruction_organization_Organization_instructions as Instructions,
  type NotaryInstruction_organization_Organization as Organization,
  type NotaryInstructionVariables,
  type NotaryInstruction,
} from "./notary_instructions_query.graphql";
import Styles from "./notary_instructions.module.scss";

const MESSAGES = defineMessages({
  placeholder: {
    id: "90cf8e2d-e8cd-4a3e-bab8-7f35798abb35",
    defaultMessage: "Special instructions",
  },
});

export function NotaryInstructionsCard() {
  const intl = useIntl();
  const [status, setStatus] = useState<"error" | "success" | null>(null);
  const [currentInstruction, setCurrentInstruction] = useState<
    { mode: "add"; text: string } | { mode: "edit"; id: string; text: string } | { mode: null }
  >({ mode: null });
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(NotaryInstructionQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  const upsertBundleInstructionMutation = useMutation(UpsertDocumentBundleInstructionMutation);
  const deleteDocumentBundleInstructionMutation = useMutation(
    DeleteDocumentBundleInstructionMutation,
  );

  const organization = data?.organization as Organization | null;
  const instructions = organization?.instructions;

  const reset = () => {
    setCurrentInstruction({ mode: null });
  };

  const saveInstruction = async (id: Instructions["id"] | null = null) => {
    if (currentInstruction.mode === null) {
      return;
    }

    try {
      await upsertBundleInstructionMutation({
        variables: {
          input: {
            text: currentInstruction.text,
            organizationId: activeOrganizationId,
            documentBundleInstructionId: id,
          },
        },
        update:
          currentInstruction.mode === "edit"
            ? undefined
            : (cacheProxy, { data }) => {
                const organization = cacheProxy.readQuery<
                  NotaryInstruction,
                  NotaryInstructionVariables
                >({
                  query: NotaryInstructionQuery,
                  variables: { organizationId: activeOrganizationId! },
                })?.organization as Organization;

                const newInstructions = [
                  ...organization.instructions,
                  data!.upsertDocumentBundleInstruction?.documentBundleInstruction,
                ];

                cacheProxy.writeQuery({
                  query: NotaryInstructionQuery,
                  variables: { organizationId: activeOrganizationId! },
                  data: { organization: { ...organization, instructions: newInstructions } },
                });
              },
      });
      setStatus("success");
      reset();
    } catch {
      setStatus("error");
    }
  };

  const deleteInstruction = async (id: Instructions["id"]) => {
    try {
      await deleteDocumentBundleInstructionMutation({
        variables: { input: { id } },
        update: (cacheProxy, { data }) => {
          const organization = cacheProxy.readQuery<NotaryInstruction, NotaryInstructionVariables>({
            query: NotaryInstructionQuery,
            variables: { organizationId: activeOrganizationId! },
          })?.organization as Organization;

          const newInstructions = organization.instructions.filter(
            (inst) => inst.id !== data!.deleteDocumentBundleInstruction?.deletedInstructionId,
          );

          cacheProxy.writeQuery({
            query: NotaryInstructionQuery,
            variables: { organizationId: activeOrganizationId! },
            data: { organization: { ...organization, instructions: newInstructions } },
          });
        },
      });
      setStatus("success");
      reset();
    } catch {
      setStatus("error");
    }
  };

  return loading ? (
    <LoadingIndicator />
  ) : (
    <Card
      footer={
        <Button
          onClick={() => setCurrentInstruction({ mode: "add", text: "" })}
          disabled={Boolean(currentInstruction.mode)}
          buttonColor="action"
          variant="secondary"
          automationId="add-notary-instuction"
          className="AddNotaryInstruction"
          withIcon={{ name: "add", placement: "left" }}
        >
          <FormattedMessage
            id="02cad407-437b-4f27-8903-f53004668651"
            defaultMessage="Add New Instruction"
          />
        </Button>
      }
    >
      <CardText>
        <FormattedMessage
          id="eb6e3891-8594-4881-8a23-1f599f928a22"
          defaultMessage="Advise notaries of any special instructions you’d like to provide to them. This will appear on all transactions."
        />
      </CardText>
      {instructions?.map((inst, i) => (
        <div key={i} className={Styles.instructionWrapper}>
          {currentInstruction.mode === "edit" && currentInstruction.id === inst.id ? (
            <textarea
              onChange={(e) =>
                setCurrentInstruction({ ...currentInstruction, text: e.target.value })
              }
              className={Styles.textarea}
              value={currentInstruction.text}
              data-automation-id="note-textarea"
            />
          ) : (
            <div className={Styles.instruction}>{inst.text}</div>
          )}

          <div className={Styles.buttonWrapper}>
            {currentInstruction.mode === "edit" && currentInstruction.id === inst.id ? (
              <Button
                onClick={() => {
                  saveInstruction(inst.id);
                }}
                className={Styles.buttonLeft}
                buttonColor="action"
                variant="secondary"
                automationId="save-note"
              >
                <FormattedMessage id="5fa1f910-db1b-40ef-86b0-7a379e8d3720" defaultMessage="Save" />
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setCurrentInstruction({ mode: "edit", id: inst.id, text: inst.text });
                }}
                className={Styles.buttonLeft}
                buttonColor="action"
                variant="secondary"
                automationId="edit-note"
              >
                <FormattedMessage id="238a032f-d4d6-43b9-ab21-9add272e4a39" defaultMessage="Edit" />
              </Button>
            )}

            <Button
              onClick={() => {
                deleteInstruction(inst.id);
              }}
              buttonColor="danger"
              variant="secondary"
              automationId="delete-note"
            >
              <FormattedMessage id="a5fb10f7-76e5-4629-a8bf-2aa037381cd5" defaultMessage="Delete" />
            </Button>
          </div>
        </div>
      ))}

      {currentInstruction.mode === "add" && (
        <>
          <CardHeading>
            <FormattedMessage
              id="bc6d039a-1baf-4e2f-a5b6-1fb8a3c88c7c"
              defaultMessage="New Instruction"
            />
          </CardHeading>
          <textarea
            onChange={(e) => setCurrentInstruction({ ...currentInstruction, text: e.target.value })}
            className={Styles.textarea}
            placeholder={intl.formatMessage(MESSAGES.placeholder)}
            value={currentInstruction.text}
            data-automation-id="note-textarea"
          />
          <div className={Styles.buttonWrapper}>
            <Button
              onClick={() => reset()}
              className={Styles.buttonLeft}
              variant="secondary"
              buttonColor="action"
              automationId="cancel-note"
            >
              <FormattedMessage id="a1a67a52-6827-4bcc-858c-96cab52b03a0" defaultMessage="Cancel" />
            </Button>
            <Button onClick={() => saveInstruction()} buttonColor="action" variant="primary">
              <FormattedMessage
                id="fc0cf746-1db9-410f-b45a-539f25b01253"
                defaultMessage="Save Changes"
              />
            </Button>
          </div>
        </>
      )}
      {status === "error" && <MutationErrorModal onClick={() => setStatus(null)} />}
    </Card>
  );
}
