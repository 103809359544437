import "common/form/form.scss";

import { defineMessages, useIntl } from "react-intl";
import { useState } from "react";

import {
  getPronounsFormDataForUser,
  Pronouns,
  PronounsSubHookForm,
  type FormValues as PronounsFormValues,
} from "common/core/form/subforms/pronouns";
import { useMutation } from "util/graphql";
// eslint-disable-next-line import/no-restricted-paths
import UpdateCustomerProfile from "signer_portal/settings/customer_profile_form/update_customer_profile_mutation.graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { useForm } from "common/core/form";
import { AddressInput, type Address as AddressFormValues } from "common/core/form/address";
import { Card, CardSection } from "common/core/card";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import SaveButton from "common/core/save_button";

import type { CustomerProfile_viewer_user } from "../../customer_profile_query.graphql";
import Style from "./index.module.scss";

export type FormValues = { address: AddressFormValues } & PronounsFormValues & {
    year: string;
    month: string;
    day: string;
  };

type Props = {
  user: CustomerProfile_viewer_user;
  fullWidthFooter?: boolean;
};

const messages = defineMessages({
  profileSettingsSuccess: {
    id: "6beb0790-fd47-4bda-be59-23c7e84be196",
    defaultMessage: "Profile details successfully updated",
  },
  pronounsLegend: {
    id: "1c1119ab-56d2-4617-bf04-693b64be5580",
    defaultMessage: "Pronouns (optional)",
  },
  addressLegend: {
    id: "f4cb9365-f5c3-46c9-83f6-da305c9a41f9",
    defaultMessage: "Address",
  },
  submit: {
    id: "ef49376d-404b-4213-8694-ca08b25e54b6",
    defaultMessage: "Save changes",
  },
});

function CustomerProfileForm({ user, fullWidthFooter }: Props) {
  const intl = useIntl();
  const [isSubmitError, setIsSubmitError] = useState<boolean>(false);
  const address = user.profile?.address;

  const { line1, line2, city, state, postal, country } = address || {};

  const defaultValues = {
    address: {
      line1: line1 || "",
      line2: line2 || "",
      city: city || "",
      state: state || "",
      postal: postal || "",
      country: country || "",
    },
    ...getPronounsFormDataForUser({ pronouns: user.pronouns as Pronouns }),
  };

  const form = useForm<FormValues>({ defaultValues });
  const updateCustomerProfileMutation = useMutation(UpdateCustomerProfile);

  const save = (values: FormValues) => {
    const { address, pronouns, customPronouns } = values;

    const params = {
      address,
    };
    return updateCustomerProfileMutation({
      variables: {
        mutationInput: {
          id: user.id,
          pronouns: pronouns === Pronouns.OTHER ? customPronouns : pronouns,
          address: params.address,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(messages.profileSettingsSuccess),
        });
      })
      .catch(() => {
        setIsSubmitError(true);
      });
  };

  const watchPronouns = form.watch("pronouns");

  return (
    <>
      <form onSubmit={form.handleSubmit(save)}>
        <Card
          className={Style.formWrapper}
          {...(!fullWidthFooter && {
            footer: (
              <SaveButton
                className={Style.saveButton}
                title={intl.formatMessage(messages.submit)}
                disabled={!form.formState.isValid}
              />
            ),
          })}
        >
          <CardSection>
            <fieldset>
              <legend>{intl.formatMessage(messages.pronounsLegend)}</legend>
              <PronounsSubHookForm
                selectedPronouns={watchPronouns}
                pronounsProps={form.register("pronouns")}
                customPronounsProps={form.register("customPronouns")}
              />
            </fieldset>
          </CardSection>
          <CardSection>
            <fieldset>
              <legend>{intl.formatMessage(messages.addressLegend)}</legend>
              <AddressInput<"address", FormValues> form={form} name="address" required />
            </fieldset>
          </CardSection>
          {fullWidthFooter && (
            <SaveButton
              className={Style.saveButton}
              title={intl.formatMessage(messages.submit)}
              fullWidth
              disabled={!form.formState.isValid}
            />
          )}
        </Card>
      </form>
      {isSubmitError && <MutationErrorModal onClick={() => setIsSubmitError(false)} />}
    </>
  );
}

export default CustomerProfileForm;
