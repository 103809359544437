import { useEffect, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { reduxForm, type InjectedFormProps, SubmissionError } from "redux-form";

import {
  NotaryDocumentTypes,
  ValidationRequirements,
  NotaryProfileInvalidFields as InvalidFields,
} from "graphql_globals";
import { composeValidators } from "util/form";
import { normalizeToNumber } from "util/normalize";
import { splitDate } from "util/date";
import { validatePresence, validateFutureDay } from "validators/form";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedMonthField } from "common/form/fields/month";
import TipWell from "common/core/tip_well";
import { DeprecatedSubForm } from "common/form/sub_form";
import { DeprecatedSubFormSection } from "common/form/sub_form/section";
import { DeprecatedFormRow } from "common/form/elements/row";
import { isNotaryODN } from "common/notary/capacity";
import { NotaryProfileWizardAssetUploader } from "common/notary/profile_wizard/upload";
import { customMessage } from "errors/form";
import type { FormError } from "errors/util";

import type {
  NotaryProfileWizardInsuranceDetails as User,
  NotaryProfileWizardInsuranceDetails_notaryProfile as NotaryProfile,
} from "./index_fragment.graphql";
import {
  findDocumentLabel,
  initializeNotaryDocumentField,
  useChangeFileCallback,
  type FileInfo,
  type SubmitType,
} from "../section_utils";
import Styles from "./insurance_details.module.scss";

export const INSURANCE_PATH = "insurance";

const FIELDS = [
  InvalidFields.INVALID_INSURER,
  InvalidFields.INVALID_POLICY_NUMBER,
  InvalidFields.INVALID_POLICY_DOCUMENT,
  InvalidFields.INVALID_POLICY_EXPIRY,
];

type FormValues = {
  insurer: string | null | undefined;
  policyExpiryDay: string | undefined;
  policyExpiryMonth: string | undefined;
  policyExpiryYear: string | undefined;
  policyKey: FileInfo | undefined;
  policyAmount: number | string | null;
};
type Input = {
  insurer: string | null;
  policyExpiry: string | null;
  notaryDocuments:
    | { keys: string[]; name: string | undefined; documentType: NotaryDocumentTypes }[]
    | null;
  policyAmountInCents: number | null;
  viewedEo: boolean;
};
type Props = {
  user: User;
  onNext: (input: Input) => void;
  renderFooter: (handleSubmit: () => SubmitType) => ReactNode;
};
type InnerProps = InjectedFormProps<FormValues, Props> & Props;

const EO_POLICY_LABEL = (
  <FormattedMessage
    id="a4536a93-223e-48b5-b720-3f98ee5de25e"
    defaultMessage="E&O Policy Document"
  />
);

type InsuranceDetailsType =
  | { id: "InsuranceDetails"; completed: boolean; route: typeof INSURANCE_PATH }
  | false;

function fieldsRequired(notaryProfile: NotaryProfile) {
  return notaryProfile.validation.invalidFields.some(
    (field) => field !== null && FIELDS.includes(field),
  );
}

export function insuranceDetailsSection(
  lookup: Set<ValidationRequirements>,
  notaryProfile: NotaryProfile,
): InsuranceDetailsType {
  const completed =
    !FIELDS.some((field) => notaryProfile.validation.invalidFields.includes(field)) &&
    Boolean(notaryProfile.viewedEo);
  return (
    lookup.has(ValidationRequirements.POLICY_DETAILS) && {
      id: "InsuranceDetails",
      completed,
      route: INSURANCE_PATH,
    }
  );
}
function formatCents(value: string, precision: number = 2): string {
  return (Number(value) / 100).toFixed(precision);
}

function InsuranceDetails({
  user,
  initialize,
  change,
  handleSubmit,
  onNext,
  renderFooter,
}: InnerProps) {
  const notaryProfile = user.notaryProfile!;
  const serializeForm = (fv: FormValues) => {
    const policyAmount = fv.policyAmount;
    if ((policyAmount || policyAmount === 0) && Number(policyAmount) < 25_000) {
      throw new SubmissionError<FormValues, FormError>({
        policyAmount: customMessage({
          message: "This insurance amount does not meet the requirements stated above.",
        }),
      });
    }
    return onNext({
      insurer: fv.insurer ?? null,
      policyExpiry:
        fv.policyExpiryYear && fv.policyExpiryMonth && fv.policyExpiryDay
          ? `${fv.policyExpiryYear}-${fv.policyExpiryMonth}-${fv.policyExpiryDay}`
          : null,
      notaryDocuments:
        typeof fv.policyKey?.key === "string"
          ? [
              {
                keys: [fv.policyKey.key],
                name: fv.policyKey.file?.name,
                documentType: NotaryDocumentTypes.POLICY,
              },
            ]
          : null,
      policyAmountInCents: policyAmount ? Number(policyAmount) * 100 : null,
      viewedEo: true,
    });
  };
  const handlePolicyKeyChange = useChangeFileCallback("policyKey", change);

  useEffect(() => {
    const { notaryDocuments, insurer, policyExpiry, policyAmountInCents } = notaryProfile;
    const [policyExpiryYear, policyExpiryMonth, policyExpiryDay] = splitDate(policyExpiry);
    const policyAmount = policyAmountInCents
      ? formatCents(policyAmountInCents.toString(), 0)
      : null;

    initialize({
      insurer: insurer || null,
      policyExpiryDay,
      policyExpiryMonth,
      policyExpiryYear,
      policyKey: initializeNotaryDocumentField(notaryDocuments, NotaryDocumentTypes.POLICY),
      policyAmount,
    });
  }, []);

  return (
    <>
      <div className={Styles.bodyContent}>
        <div className={Styles.header}>
          <FormattedMessage
            id="00608f21-74b6-4194-80ac-1c38a2bb1e0c"
            defaultMessage="Insurance Details"
            tagName="h3"
          />
          {!isNotaryODN(notaryProfile) && (
            <div className={Styles.optionalPill}>
              <FormattedMessage
                id="513d2114-76c8-4c23-a3a6-ad9d1df46325"
                defaultMessage="Optional"
              />
            </div>
          )}
        </div>

        <FormattedMessage
          id="b40b1f66-6444-4600-9566-b7c8132c324a"
          defaultMessage="Upload a copy of Errors & Omissions (E&O) policy."
          tagName="h4"
        />

        <DeprecatedSubForm>
          <DeprecatedSubFormSection
            fullWidth
            tipWell={
              <TipWell
                className={Styles.card}
                heading={
                  <FormattedMessage
                    id="c0e56604-0ccb-4e91-8f5c-29c6032db55c"
                    defaultMessage="Is E&O insurance required?"
                  />
                }
              >
                <FormattedMessage
                  id="00608f21-74b6-4194-80ac-1c38a2bb1e0c"
                  defaultMessage="Yes, if you’re an independent notary managing on-demand calls, you need a personal E&O policy."
                  tagName="p"
                />
                <FormattedMessage
                  id="d5af8b8f-fa20-480b-9ad8-dab23f08dd0c"
                  defaultMessage="In-house notaries handling employer transactions, should enter their employer’s E&O policy, if provided."
                  tagName="p"
                />
              </TipWell>
            }
          >
            <DeprecatedFormRow>
              <FormattedMessage
                id="cd380c13-4817-44e7-be43-a19939a8c44a"
                defaultMessage="E&O insurance provider"
                tagName="label"
              />
              <DeprecatedTextField
                id="insurer"
                automationId="insurer"
                name="insurer"
                useStyledInput
              />
              <FormGroupErrors fields={["insurer"]} />
            </DeprecatedFormRow>
            <DeprecatedFormRow>
              <FormattedMessage
                id="c52bff4f-e90f-48f1-b3e4-9e09453b9c17"
                defaultMessage="Insurance amount"
                tagName="label"
              />
              <div className={Styles.dollar}>
                <div className={Styles.symbol}>$</div>
                <DeprecatedTextField
                  id="policyAmount"
                  name="policyAmount"
                  automationId="policyAmount"
                  useStyledInput
                  normalize={normalizeToNumber}
                />
              </div>
              <FormGroupErrors fields={["policyAmount"]} />
            </DeprecatedFormRow>
            <FormGroup
              disableFormRowStyle
              fields={["policyExpiryYear", "policyExpiryMonth", "policyExpiryDay"]}
            >
              <FormattedMessage
                id="7ef85f7e-efa2-4e42-92d9-790a1880a178"
                defaultMessage="Policy expiration date"
                tagName="label"
              />
              <DeprecatedMultipartRow>
                <DeprecatedMultipartColumn width={6}>
                  <DeprecatedMonthField
                    name="policyExpiryMonth"
                    automationId="policyExpiryMonth"
                    useStyledInput
                    searchable={false}
                    clearable={false}
                  />
                </DeprecatedMultipartColumn>
                <DeprecatedMultipartColumn width={2}>
                  <DeprecatedTextField
                    name="policyExpiryDay"
                    automationId="policyExpiryDay"
                    placeholder="DD"
                    normalize={normalizeToNumber}
                    maxLength="2"
                    useStyledInput
                  />
                </DeprecatedMultipartColumn>
                <DeprecatedMultipartColumn width={4}>
                  <DeprecatedTextField
                    name="policyExpiryYear"
                    automationId="policyExpiryYear"
                    placeholder="YYYY"
                    normalize={normalizeToNumber}
                    maxLength="4"
                    useStyledInput
                  />
                </DeprecatedMultipartColumn>
              </DeprecatedMultipartRow>
              <FormGroupErrors
                fields={["policyExpiryDay", "policyExpiryMonth", "policyExpiryYear"]}
              />
            </FormGroup>
          </DeprecatedSubFormSection>

          <DeprecatedSubFormSection fullWidth>
            <FormattedMessage
              id="309a4569-686b-4bd2-905c-dd3c18387a30"
              defaultMessage="Upload a copy of your E&O Policy"
              tagName="p"
            />
            <NotaryProfileWizardAssetUploader
              persistedValue={findDocumentLabel(
                notaryProfile.notaryDocuments,
                NotaryDocumentTypes.POLICY,
                EO_POLICY_LABEL,
              )}
              onChange={handlePolicyKeyChange}
            />
            <FormGroupErrors fields={["policyKey"]} />
          </DeprecatedSubFormSection>
        </DeprecatedSubForm>
      </div>
      {renderFooter(handleSubmit(serializeForm))}
    </>
  );
}

const detailsRequiredValidation = composeValidators(
  validatePresence({ field: "insurer", label: "E&O Insurer" }),
  validatePresence({ field: "policyAmount", label: "Insurance amount" }),
  validatePresence({ field: "policyExpiryDay", label: "Policy expiration day" }),
  validatePresence({ field: "policyAmount", label: "Insurance amount" }),
  validatePresence({ field: "policyExpiryDay", label: "Policy expiration day" }),
  validatePresence({ field: "policyExpiryMonth", label: "Policy expiration month" }),
  validatePresence({ field: "policyExpiryYear", label: "Policy expiration year" }),
  validatePresence({ field: "policyKey", label: "Proof of E&O policy" }),
  validateFutureDay({
    field: "policyExpiryYear",
    label: "Policy expiration",
    monthField: "policyExpiryMonth",
    dayField: "policyExpiryDay",
    yearField: "policyExpiryYear",
  }),
);

export default reduxForm<FormValues, Props>({
  form: "notaryProfileWizardInsuranceDetails",
  validate: (values, props) => {
    return fieldsRequired(props.user.notaryProfile!) ? detailsRequiredValidation(values) : {};
  },
})(InsuranceDetails);
